import React, { Component } from "react";

import "../../Table.css";
import Container from "../../Container"
import Footer from "../../Footer"
import { NavLink } from "react-router-dom";
import incidentImg from "../../../img/incident.webp"
import Nav from "../../Nav";

class ServiceIncidentResponse extends Component {
  render() {
    return (
      <Container>
    <Nav />
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">Services </h2>
              <p class="opacity">
                <i></i>
              </p>
              <h1 class="lead highfont">
                Work with TrustThink to securely design, develop and deploy new
                technologies.
              </h1>
            </div>
          </div>
        </div>
        <body>
          <div class="grid10 addmargin">

          <div class="title">
          <h2>Incident Response and Risk Management</h2>
            </div>

            <img class="service_images" src={incidentImg} alt="Shield antivirus" style= {{height: 400, width: 650}}/>

            <div class="imgtext">
          
              <p>
              <strong>The Evolving Need for Robust Incident Response:</strong> In an era where cyber threats are increasingly 
              sophisticated, having a proactive incident response and risk management strategy is crucial for protecting critical 
              infrastructure. Effective crisis management can significantly mitigate the impacts of potential security incidents.
              </p>

              <p>
              <strong>TrustThink's Proven Track Record:</strong> TrustThink has developed practical tools and 
              strategies for incident response, exemplified by our tabletop guide for robotic assisted surgery systems. 
              This experience underlines our capability to craft customized response drills and exercises tailored to specific 
              industry needs.
              </p>

              <p>
              <strong>Custom Drills and Tabletop Exercises:</strong> Our services include designing and facilitating 
              custom drills and tabletop exercises that test and refine organizational response strategies. These simulations
              are designed to ensure all team members are prepared and capable of acting swiftly and effectively in a crisis.
              </p>

              <p>
              <strong>Comprehensive Risk Management Planning:</strong> We also offer comprehensive risk management planning services, 
              helping organizations identify potential risks, assess vulnerabilities, and implement strategic defenses to protect against 
              and mitigate these risks. Our approach ensures that all aspects of risk are considered, from initial assessment to continuous 
              improvement and compliance with relevant standards.
              </p>


            </div>
            <div class="bottom">
                Discover how TrustThink can empower your organization with leading-edge solutions tailored to the unique challenges of your industry. Contact us today to safeguard your technologies and propel your innovations forward.
               <p>
                <a href="mailto:solutions@trustthink.net">
                            <strong>SOLUTIONS@TRUSTTHINK.NET</strong>
                </a>
              </p>
              
              
            </div>
            {/* Back button */}
            <NavLink to="/services">
              <button type="button" class="back_btn">                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>

          </div>

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          />
        </body>
        <Footer/>
      </Container>
    );
  }
}

export default ServiceIncidentResponse;
