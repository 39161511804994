import React, { Component } from "react";

import "../../Table.css";
import Container from "../../Container"
import Footer from "../../Footer"
import { NavLink } from "react-router-dom";
import infrastructureImg from "../../../img/infrastructure.webp"
import Nav from "../../Nav";

class ServiceInfrastructure extends Component {
  render() {
    return (
      <Container>
    <Nav />
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">Services </h2>
              <p class="opacity">
                <i></i>
              </p>
              <h1 class="lead highfont">
                Work with TrustThink to securely design, develop and deploy new
                technologies.
              </h1>
            </div>
          </div>
        </div>
        <body>
          <div class="grid10 addmargin">

          <div class="title">
          <h2>Smart Infrastructure Security Solutions</h2>
            </div>

            <img class="service_images" src={infrastructureImg} alt="Digital buildings" style= {{height: 400, width: 800}}/>

            <div class="imgtext">
          
              <p>
              <strong>The Need for Smart Infrastructure:</strong> As cities evolve into smart urban environments, the integration of 
              robust security measures becomes essential. Smart infrastructure projects, including autonomous shuttles and IoT-enabled 
              devices, are increasingly susceptible to cyber threats, making security a top priority.
              </p>

              <p>
              <strong>TrustThink's Proven Experience:</strong> TrustThink has a proven track record in developing secure architectures 
              for smart infrastructure projects, such as autonomous shuttle security and comprehensive enterprise security systems. 
              Our expertise in data protection and encryption further strengthens our capability to safeguard sensitive information.
              </p>

              <p>
              <strong>Security Solutions for Smart City Projects:</strong> We offer tailored security solutions to ensure the integrity 
              and safety of smart city infrastructures. This includes deploying advanced IoT security measures and ensuring compliance 
              with evolving smart city standards. Our approach not only protects but also enhances the operational efficiency of smart 
              urban projects.
              </p>

              <p>
              <strong>Ensuring Compliance and Data Protection:</strong> Our services extend to navigating the complex landscape of 
              regulatory compliance and data protection standards. By leveraging our deep knowledge in encryption and cybersecurity, 
              we ensure that smart infrastructure projects meet rigorous security expectations and safeguard against potential breaches.
              </p>


            </div>
            <div class="bottom">
                Discover how TrustThink can empower your organization with leading-edge solutions tailored to the unique challenges of your industry. Contact us today to safeguard your technologies and propel your innovations forward.
               <p>
                <a href="mailto:solutions@trustthink.net">
                            <strong>SOLUTIONS@TRUSTTHINK.NET</strong>
                </a>
              </p>
              
            </div>
            {/* Back button */}
            <NavLink to="/services">
              <button type="button" class="back_btn">                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>

          </div>

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          />
        </body>
        <Footer/>
      </Container>
    );
  }
}

export default ServiceInfrastructure;
