import React, { Component } from "react";

import "../../Table.css";
import Container from "../../Container"
import Footer from "../../Footer"
import { NavLink } from "react-router-dom";
import quantumImg from "../../../img/quantum.webp"
import Nav from "../../Nav";
class ServiceQuantum extends Component {
  render() {
    return (
      <Container>
      <Nav />
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">Services </h2>
              <p class="opacity">
                <i></i>
              </p>
              <h1 class="lead highfont">
                Work with TrustThink to securely design, develop and deploy new
                technologies.
              </h1>
            </div>
          </div>
        </div>
        <body>
          <div class="grid10 addmargin">

          <div class="title">
          <h2>Quantum Computing Impact Assessments</h2>
            </div>

            <img class="service_images" src={quantumImg} alt="Digital Network image" style= {{height: 400, width: 800}}/>

            <div class="imgtext">
          
              <p>
              <strong>The Need for Assessment:</strong> The advent of quantum computing represents a paradigm shift with significant implications for cryptographic security. As quantum computers become more capable, 
              they pose a risk to the cryptographic protocols that currently secure everything from internet communications to financial transactions.
              </p>

              <p>
              <strong>TrustThink's Approach:</strong> TrustThink is dedicated to assessing the impacts of quantum computing on existing security infrastructures. We provide comprehensive 
              evaluations that identify vulnerabilities in cryptographic systems and predict the timeline of potential quantum threats, enabling organizations to prepare and respond proactively.
              </p>

              <p>
              <strong>Evaluating Risks and Opportunities:</strong> Our quantum computing impact assessments delve into the specific risks associated with the advent of quantum capabilities, particularly 
              concerning cryptographic security. We analyze how current encryption methods might be compromised and offer strategic recommendations for transitioning to quantum-resistant cryptographic protocols.
              </p>

              <p>
              <strong>Strategic and Tactical Recommendations:</strong> Beyond identifying risks, TrustThink provides actionable guidance on updating system designs and adopting new technologies to mitigate the 
              quantum threat. Our tailored recommendations help organizations prioritize updates and investments in technology to ensure long-term security in a post-quantum world.
              </p>


            </div>
            <div class="bottom">
                Discover how TrustThink can empower your organization with leading-edge solutions tailored to the unique challenges of your industry. Contact us today to safeguard your technologies and propel your innovations forward.
               <p>
                <a href="mailto:solutions@trustthink.net">
                            <strong>SOLUTIONS@TRUSTTHINK.NET</strong>
                </a>
              </p>
              
            </div>
            {/* Back button */}
            <NavLink to="/services">
              <button type="button" class="back_btn">                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>

          </div>

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          />
        </body>
        <Footer/>
      </Container>
    );
  }
}

export default ServiceQuantum;
