import React, { Component } from "react";

import "../../Table.css";
import Container from "../../Container"
import Footer from "../../Footer"
import { NavLink } from "react-router-dom";
import blockchainImg from "../../../img/blockchain.webp"
import Nav from "../../Nav";
class ServiceBlockchain extends Component {
  render() {
    return (
      <Container>
      <Nav />
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">Services </h2>
              <p class="opacity">
                <i></i>
              </p>
              <h1 class="lead highfont">
                Work with TrustThink to securely design, develop and deploy new
                technologies.
              </h1>
            </div>
          </div>
        </div>
        <body>
          <div class="grid10 addmargin">

          <div class="title">
          <h2>Blockchain Security Consulting</h2>
            </div>

            <img class="service_images" src={blockchainImg} alt="" style= {{height: 400, width: 800}}/>

            <div class="imgtext">
          
              <p>
              <strong>The Need for Secure Blockchain Applications:</strong> As blockchain technology revolutionizes sectors 
              like transportation and logistics, ensuring these systems are secure against vulnerabilities becomes paramount. 
              The inherent complexities of blockchain make it crucial to focus on robust security measures.
              </p>

              <p>
              <strong>TrustThink's Blockchain Initiatives:</strong> TrustThink has significant experience in the 
              blockchain space, having designed and prototyped blockchain solutions for logistics applications and smart 
              energy contracts. Our expertise spans from the initial design to ensuring these applications are secure and 
              compliant with industry standards.
              </p>

              <p>
              <strong>Expertise in Securing Blockchain Applications:</strong> We offer comprehensive services to secure blockchain 
              implementations, ensuring they are resilient against attacks while maintaining transparency and efficiency. Our team helps 
              organizations implement encryption techniques and smart contracts securely, enhancing overall blockchain application 
              integrity.
              </p>

              <p>
              <strong>Enabling Blockchain Innovations in Transportation and Logistics: </strong> Our consultancy extends to enabling 
              blockchain applications specifically tailored for the transportation and logistics sectors. By integrating blockchain into 
              these industries, we help companies achieve greater efficiency, traceability, and security in their operations.
              </p>

            

            </div>
            <div class="bottom">
                Discover how TrustThink can empower your organization with leading-edge solutions tailored to the unique challenges of your industry. Contact us today to safeguard your technologies and propel your innovations forward.
               <p>
                <a href="mailto:solutions@trustthink.net">
                            <strong>SOLUTIONS@TRUSTTHINK.NET</strong>
                </a>
              </p>
              
            </div>
            {/* Back button */}
            <NavLink to="/services">
              <button type="button" class="back_btn">                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>

          </div>

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          />
        </body>
        <Footer/>
      </Container>
    );
  }
}

export default ServiceBlockchain;
