import React, { Component } from "react";

import "../Table.css";
import Container from "../Container";
class TableListHealth extends Component {
  render() {
    return (
      <Container>
        <body>
          <section class="showcase">
            <div class="container-fluid p-0 ">
              <div class="row no-gutters">
                <div class="col-lg-5 order-lg-1 text-white showcase-img picture8">
                  <span></span>
                </div>
                <div class="col-lg-6 my-auto order-lg-1 showcase-text">
                  <p class="lead mb-0">
                    TrustThink has a long history of automotive cyber security
                    experience. Our engineers have helped develop new approaches
                    for wireless telematics security and diagnostic port access
                    controls. We’ve defined cyber security requirements for
                    municipal autonomous shuttles. Our engineers have also
                    worked with the Federal Government to understand the latest
                    security threats and approaches for automated driver
                    assistance systems (ADAS) integrated into Commercial Motor
                    Vehicles (CMVs). Contact us to deploy a secure vehicle fleet
                    or to integrate secure autonomy.
                  </p>
                </div>
              </div>
              <br></br>
            </div>
          </section>
          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
        </body>
      </Container>
    );
  }
}

export default TableListHealth;
