import React, { Component } from "react";

import "../Table.css";
import Container from "../Container";
import droneImage from '../../img/drones-lg.webp';
import Footer from "../Footer";
import { NavLink } from "react-router-dom";
import Nav from "../Nav";
class CyberSolDrone extends Component {
  render() {
    return (
      <Container>
        <Nav/>
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">TrustThink Solutions</h2>
              <p class="opacity">
                <i></i>
              </p>
              <p class="lead highfont">
              Explore our tailored solutions that empower organizations to navigate technological changes and enhance security in a dynamic world.
              </p>
            </div>
          </div>
        </div>
        <body>
          <div class="grid10 addmargin">
            <div class="title">
            <h2>Aerial Drone Cybersecurity Assurance Package: Elevate Your Drone’s Defense</h2>
            </div>

            <img class="images" src={droneImage} alt="Two Drones in flight" style= {{height: 200, width: 300}}/>

            <div class="imgtext">
          

              <p>
              Secure your place in the sky with TrustThink’s Aerial Cybersecurity Assurance Package, exclusively designed for drone manufacturers. 
              In an era where drone technology is rapidly advancing and integrating into critical sectors, cybersecurity becomes paramount. 
              Our package offers a holistic suite of cybersecurity design and documentation services, crafted to safeguard your drones against evolving 
              cyber threats and comply with emerging regulatory standards. Leverage our extensive experience in drone cybersecurity, underpinned by our 
              collaborative work to develop a trusted drone cybersecurity program. This package encompasses:
              </p>
              <li>
              Tailored Cybersecurity Frameworks: Customized security protocols based on your drone’s specific use case, technology, and operational environment.
              </li>
              <li>
              Comprehensive Risk Assessments: Identifying potential vulnerabilities within your drone systems and communication networks to fortify your defenses.
              </li>
              <li>
              Strategic Security Design Consultation: Expert advice on embedding robust security features from the ground up, including secure communication channels, encrypted data storage, and resilient authentication mechanisms.
              </li>
              <li>
              Detailed Cybersecurity Documentation: Documentation to support regulatory submissions and certifications, enhancing trust with clients and regulatory bodies alike.
              </li>
              <li>
              Ongoing Compliance and Updates: Guidance on adapting to new cybersecurity regulations and standards, ensuring your drones remain at the forefront of security and compliance.
              </li>
            </div>
            <div class="bottom">

              <a href="mailto:solutions@trustthink.net">
                          <strong>SOLUTIONS@TRUSTTHINK.NET</strong>
              </a>

           
              
            </div>

           {/* Back button */}
           <NavLink to="/cybersolutions/overview">
              <button type="button" class="back_btn">
                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>
          </div>
          




          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          />
        </body>
        
        <Footer/>
      </Container>
      
    );
  }
}

export default CyberSolDrone;
