import React, { Component } from "react";
import Nav from "../../Nav";
import "../../Table.css";
import Container from "../../Container";
import graph from "../../../img/drones.png";

class UncrewedVehicles extends Component {
  render() {
    return (
      <Container>
        <Nav />

        <div class="textbox">
          <h4>The start of a Trusted Autonomy Future</h4>
          <p>
            Uncrewed vehicles are operating today on land, at sea, and in the
            air. Automated vehicles are gaining traction across diverse
            operational contexts, from transporting passengers and freight, to
            surveying power lines and infrastructure, to collecting critical
            reconnaissance data. As society begins to rely on uncrewed vehicles
            for mission-critical purposes, their operational availability and
            the safety of passengers and bystanders must be assured. Sensors,
            data processing, and autonomy technologies all continue to evolve
            and mature. The integration of these technologies within vehicles
            and their ecosystems opens opportunities for the exposure of
            security weaknesses within both vehicle and infrastructure device
            design. Intra-vehicle messages, images, video and sensor data relied
            upon for route planning, navigation, and collision avoidance must be
            integrity-protected. Messaging from infrastructure devices must be
            validated prior to use.
          </p>
          <img src={graph}></img>
          <p>
            Unfortunately, there are no existing cyber security standards to
            easily apply across the range of vehicle types that operate in the
            land, sea and air environments. However, all of these uncrewed
            vehicle types share similar components and characteristics. Small
            Unmanned Aerial Systems (sUAS) integrate numerous communication
            capabilities - Cellular, Wi-Fi, Global Positioning System (GPS) and
            others. sUAS also integrate numerous sensor packages that provide
            data for object identification, motion control, and path planning.
            Both uncrewed land and uncrewed marine vessels integrate similar
            sub-systems. These similarities allow us to define minimum security
            controls that should be implemented by any uncrewed vehicle,
            regardless of the operational context. For example, most uncrewed
            vehicles should implement commercial best practices for cryptography
            and key management, encrypt data-at-rest, integrity-protect navaids,
            validate message integrity, segment sensitive networks from
            non-sensitive networks, encrypt and authenticate wireless data
            transmissions and lock down or disable physical ports. All uncrewed
            vehicle manufacturers should also follow a well-defined secure
            development and integration process that includes testing
            sub-components for vulnerabilities. Above and beyond these minimal
            security controls, manufacturers of uncrewed vehicles should also be
            cognizant of the unique threat environment faced by their products.
            The threats to the vehicle based on the environment that it will
            operate within will dictate the need for additional security
            controls, such as fail-safe responses to the loss of GNSS or
            safety-critical sensor data, definition of role-based accounts for
            privileged functions such as diagnostic access to the vehicle, and
            implementation of perception redundancy with segmented inputs and
            paths. Securing the uncrewed vehicle is only one part of the
            equation though. Similar rigor must be taken when developing Ground
            Control Station (GCS) software. Threats to the compromise of Command
            and Control (C2), telemetry and mission data must be well documented
            and mitigated, through security controls that provide
            confidentiality, integrity and availability as necessary. Data must
            be securely stored within the GCS and/or cloud infrastructure and
            flight operations should be restricted to only authorized personnel
            as needed, through either physical or logical controls.
          </p>
        </div>
      </Container>
    );
  }
}

export default UncrewedVehicles;
