import React, { Component } from "react";
import Nav from "../../Nav";
import MetaTags from "react-meta-tags";
import "../Industries.css";
import "../../Table.css";
import Container from "../../Container";
import { NavLink } from "react-router-dom";
import Footer from "../../Footer";
import ButtonLearnMore from "../../ButtonLearnMore";

import hc_img1 from "../../../img/healthcare1.webp"
import hc_img2 from "../../../img/healthcare2.jpg"
import hc_img3 from "../../../img/healthcare3.jpg"

class Healthcare extends Component {
  render() {
    return (
      <Container>
        <MetaTags>
          <meta
            id="meta-description"
            name="description"
            content=" TrustThink has worked with industry organizations to develop tabletop
                exercises and guides for deployment of robotic assisted surgical
                systems. "
          ></meta>
        </MetaTags>
        <Nav />
        <div id="cybersol_div">
          
          <div class="stretch">
            
            <div class="container content center padding-64 tester">
              <h2 class="wide">Industries</h2>
              <h1 class="lead highfont">
              Securing Innovation, Enabling Compliance: Your Partner in Medical Device Cybersecurity and FDA 510(k) Success.
              <a href="mailto:solutions@trustthink.net" style={{color: "#1c2e4a"}}> Get Started on Your 510(k) Cybersecurity Journey Today </a>
              </h1>
            
              
              

              <p class="opacity">
                <i></i>
              </p>

            </div>
            
          </div>
        </div>

        <body>
          
          <div class="grid10"> 
              <div class="imgtext contain2">
                  <h2 class="title">
                    Securing Healthcare Environments
                  </h2>
                  
                                  
          
                  <div class = "grid4">
                  <img src={hc_img1} class="healthcare_img center" alt="Robotics-Assisted Medical Tools">
                  </img>
                  <img src={hc_img2} class="healthcare_img center" alt="Robotics-Assisted Medical Tools">
                  </img>
                  <img src={hc_img3} class="healthcare_img center" alt="Robotics-Assisted Medical Tools">
                  </img>

                  <br></br>

              </div>
          
              </div>

        <div class="contain2">
          
          <div class="grid3">
    
    <p>
            <h4>  Trends  </h4>
            
          <p>
            <b> Regulatory Compliance: </b> 
            As medical technology evolves, regulatory requirements for cybersecurity are becoming increasingly stringent. Frameworks like the FDA’s 510(k) pre-market submission guidance now 
            emphasize robust cybersecurity measures to ensure patient safety and device reliability. Compliance mandates include detailed Post-Market Cybersecurity Management Plans, Software Bills of 
            Materials (SBOMs), and Risk Assessments. These regulations drive the need for comprehensive security frameworks that address vulnerabilities across the device lifecycle. TrustThink’s expertise 
            ensures clients navigate these requirements seamlessly, delivering secure and compliant medical devices to market. 
          </p>
 
    

            <p>
            <b> Machine Learning in Medical Devices: </b> 
            Medical devices are increasingly embedding Machine Learning (ML) algorithms to enable advanced diagnostics, real-time monitoring, and predictive analytics. These 
            innovations promise improved patient outcomes but bring unique challenges, such as protecting against data poisoning and ensuring transparency in decision-making. As ML-powered devices 
            become more prevalent, manufacturers must prioritize cybersecurity to safeguard algorithms and meet FDA expectations. TrustThink helps secure these devices by integrating AI-specific security 
            measures into regulatory compliance strategies.  
            </p>

            <p>
            <b> Software as a Medical Device (SaMD): </b> 
            The rise of Software as a Medical Device (SaMD) is revolutionizing healthcare, enabling cloud-based solutions and mobile apps for diagnostics and treatment. 
            However, the digital nature of SaMD introduces risks such as unauthorized access, data breaches, and supply chain vulnerabilities. Regulatory bodies, including the FDA, have 
            heightened focus on SaMD cybersecurity, requiring manufacturers to demonstrate resilience in the face of these threats. TrustThink supports manufacturers with tailored SBOMs, threat 
            modeling, and secure architecture design to ensure compliance and reliability. 
            </p>

            <p>
            <b> Connected and Interoperable Devices: </b> 
            Modern medical devices are increasingly networked, interacting with hospital systems, IoT platforms, and electronic health records (EHR). 
            While this enhances functionality and patient care, it also broadens the attack surface. Cybersecurity for these interconnected systems must address vulnerabilities at every touchpoint, 
            from data encryption to secure device configuration. TrustThink provides comprehensive risk assessments and penetration testing to secure connected devices and ensure compliance with 
            evolving regulatory requirements. 
            </p>

            <p>
            <b> Global Market Alignment: </b> 
            As medical device manufacturers expand internationally, they must navigate a complex web of regulations, from FDA compliance in the U.S. to CE marking in Europe. Harmonizing 
            cybersecurity measures across these markets is essential to streamline development and approval processes. TrustThink’s unified approach aligns device security with global standards such as 
            ISO/IEC 27001 and the NIST Cybersecurity Framework, ensuring readiness for both domestic and international markets. 
            </p>
            

      </p>

      <p>
      <h4>  Solutions  </h4>
            
            <p>
               <b> 510(k) Compliance Services: </b> 
               Navigating the FDA’s 510(k) submission process requires robust cybersecurity measures to ensure compliance with regulatory standards. TrustThink provides end-to-end support, 
               including development of Post-Market Cybersecurity Management Plans, architectural views, and comprehensive documentation. Our services help manufacturers address pre-market and 
               post-market requirements, ensuring device security while streamlining the submission process. Whether you’re introducing a new device or updating an existing one, TrustThink ensures your 
               devices meet FDA expectations with ease. 

            </p>
   
            <p class="flex flex-col items-center bg-gray-800 border border-gray-200 rounded-xl shadow md:flex-row md:max-w-xl">
                      <div class="flex flex-col justify-between p-4 leading-normal">
                      <h5 class="mb-2 text-2xl font-bold tracking-tight text-white dark:text-white">510(k) Compliance Services</h5>
                          <p class="mb-3 font-normal text-gray-400"> <a href="mailto:solutions@trustthink.net"> Learn How We Can Streamline Your 510(k) Submission </a> </p>
        
                      </div>
            </p>

            <p>
             <b> Penetration Testing and Vulnerability Assessments: </b> 
             Cybersecurity risks in medical devices can compromise patient safety and operational reliability. TrustThink’s Penetration 
             Testing and Vulnerability Assessment services rigorously evaluate device security, identifying weaknesses and providing actionable insights to mitigate risks. 
             These assessments align with FDA guidelines, ensuring that manufacturers can proactively address vulnerabilities before devices reach the market. TrustThink’s expertise 
             delivers confidence in your device’s security posture while supporting compliance efforts. 
            </p>

            <p class="flex flex-col items-center bg-gray-800 border border-gray-200 rounded-xl shadow md:flex-row md:max-w-xl">
                      <div class="flex flex-col justify-between p-4 leading-normal">
                      <h5 class="mb-2 text-2xl font-bold tracking-tight text-white dark:text-white">  <a href="mailto:solutions@trustthink.net"> Request a Vulnerability Assessment for Your Devices </a> </h5>
                      </div>
            </p>

            <p>
             <b> Software Bill of Materials (SBOM): </b> 
             Transparency in the software supply chain is vital for mitigating risks in connected medical devices. TrustThink creates detailed SBOMs that catalog all software components, 
             including third-party and open-source libraries, as required by FDA guidelines. By identifying vulnerabilities and enabling swift remediation, an SBOM is essential for ensuring device 
             security throughout its lifecycle. TrustThink’s SBOM services integrate seamlessly with your compliance efforts, providing the visibility needed to meet regulatory demands and protect patient 
             safety. 
            </p>

            <p class="flex flex-col items-center bg-gray-800 border border-gray-200 rounded-xl shadow md:flex-row md:max-w-xl">
                      <div class="flex flex-col justify-between p-4 leading-normal">
                      <h5 class="mb-2 text-2xl font-bold tracking-tight text-white dark:text-white"> <a href="mailto:solutions@trustthink.net"> Schedule an SBOM Development Consultation </a> </h5>

                      </div>
            </p>
            


    </p>
    </div>
    

    </div>
    </div>


          <div class="grid10">
           {/* Back button */}
           <NavLink to="/industries">
              <button type="button" class="back_btn">
                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>
          </div>
          

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
        </body>
        <Footer/>
      </Container>
    );
  }
}

export default Healthcare;

{
  /*

        <ul>
          <li>
            <a
              href="https://www.uclahealth.org/medical-services/robotic-surgery/what-robotic-surgery"
              class="learnLinks"
            >
                RAS (Robot Assisted Surgery System)</a>
          </li>
    
        </ul>


        <p>
 
        </p>


*/
}
