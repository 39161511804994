import React, { Component } from "react";

import "../../Table.css";
import Container from "../../Container"
import Footer from "../../Footer"
import { NavLink } from "react-router-dom";
import encryptionImg from "../../../img/encryptionimg-lg.webp"
import Nav from "../../Nav";
class ServiceEncryptionKey extends Component {
  render() {
    return (
      <Container>
      <Nav />
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">Services </h2>
              <p class="opacity">
                <i></i>
              </p>
              <h1 class="lead highfont">
                Work with TrustThink to securely design, develop and deploy new
                technologies.
              </h1>
            </div>
          </div>
        </div>
        <body>
          <div class="grid10 addmargin">

          <div class="title">
          <h2>Encryption and Key Management Architectures</h2>
            </div>

            <img class="service_images" src={encryptionImg} alt="Locks on digital globe" style= {{height: 400, width: 800}}/>

            <div class="imgtext">
          
              <p>
              <strong>The Current Environment:</strong> As the world becomes more connected, the security of automated systems such as satellites, vehicles, and tactical radios 
              increasingly depends on advanced encryption and robust key management. The advent of quantum computing poses new challenges to traditional cryptographic methods, necessitating a 
              shift towards quantum-resistant protocols to safeguard critical communications and data integrity.
              </p>

              <p>
              <strong>TrustThink's Experience:</strong> TrustThink has been at the forefront of cryptographic innovation, helping organizations transition to quantum-resistant protocols and 
              modernizing their cryptographic architectures. Our expertise extends across various sectors, ensuring that critical systems like satellites and connected vehicles maintain confidentiality and 
              integrity through cutting-edge encryption techniques.
              </p>

              <p>
              <strong>Key and Certificate Management Plans:</strong> We develop comprehensive key and certificate management plans tailored to the specific needs of connected and automated systems. These plans ensure a 
              structured and secure approach to cryptographic key lifecycle management, from generation and storage to distribution and revocation, enhancing the overall security posture of the systems.
              </p>

              <p>
              <strong>Secure Key Management and Encryption Integration:</strong> TrustThink provides solutions for integrating secure key management and encryption protocols into existing systems. Our approach ensures that 
              all cryptographic operations are performed within a secure and controlled environment, safeguarding against both current and emerging threats. 
              </p>


            </div>
            <div class="bottom">
                Discover how TrustThink can empower your organization with leading-edge solutions tailored to the unique challenges of your industry. Contact us today to safeguard your technologies and propel your innovations forward.
              <p>
                <a href="mailto:solutions@trustthink.net">
                            <strong>SOLUTIONS@TRUSTTHINK.NET</strong>
                </a>
              </p>
              
              
            </div>
            {/* Back button */}
            <NavLink to="/services">
              <button type="button" class="back_btn">                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>

          </div>

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          />
        </body>
        <Footer/>
      </Container>
    );
  }
}

export default ServiceEncryptionKey;
