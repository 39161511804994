import React, { Component } from "react";
import Nav from "../Nav";
import "./CyberSec.css";
import Container from "../Container";
import TableListSec from "../TableListSec";
import Footer from "../Footer"

class CyberSec extends Component {
  render() {
    return (
      <Container>
        <Nav />
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">Services </h2>
              <p class="opacity">
                <i></i>
              </p>
              <p class="lead highfont">
                Secure Your Future with TrustThink: Leading Innovation and 
                Protection Across Industries
              </p>
            </div>
          </div>
        </div>

        <TableListSec />
        <Footer/>
      </Container>
     
    );
  }
}
export default CyberSec;
