import React, { Component } from "react";
import Nav from "../Nav";
import "./Hero.css";

import HeroServices from "../HeroServices";

import Container from "../Container";

const divStyle = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  paddingTop: '5%'
};

class Hero extends Component {
  render() {
    return (
      <Container>
        <Nav />
        <div id="hero_div">
          <div id="hero_text_div">
            <h1>TrustThink</h1>
            {/* Stylization is controlled by #hero_text_div p here */}
            <p>
            Securing Connected & Autonomous Systems in 
            <br></br>
            Transportation, Healthcare, & Defense

            </p>
            
            
            <p>
                AI | Cybersecurity | Innovation
            </p>
          </div>
          
          {/* <div id="buttons_div"></div> */}
        </div>
            
        <HeroServices />
      </Container>
    );
  }
}

export default Hero;
