import React, { Component } from "react";

import "../Table.css";
import Container from "../Container";
import medicalImage from '../../img/medicalequipment.webp';
import Footer from "../Footer";
import { NavLink } from "react-router-dom";
import Nav from "../Nav";
class CyberSolMedical extends Component {
  render() {
    return (
      <Container>
        <Nav/>
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">TrustThink Solutions</h2>
              <p class="opacity">
                <i></i>
              </p>
              <p class="lead highfont">
              Explore our tailored solutions that empower organizations to navigate technological changes and enhance security in a dynamic world.
              </p>
            </div>
          </div>
        </div>
        <body>
          <div class="grid10 addmargin">

          
            <div class="imgtext contain2">
            <div class="title">
               <h2>FDA 510(k) Cybersecurity Compliance Services</h2>
            </div>
            <img class="images" src={medicalImage} alt="Electronic medical devices" style= {{height: 200, width: 300}}/>
                  

                  <h5 style = {{marginTop : 70}}>
                  Ensuring Device Security, Streamlining Compliance
                  </h5>
                  <p style={{fontSize : 20}}>
                  As connected medical devices continue to evolve, ensuring robust cybersecurity is a critical component of FDA 510(k) pre-market submissions. TrustThink partners with medical device manufacturers to navigate the regulatory landscape, offering comprehensive services that safeguard device integrity, protect patient safety, and streamline compliance.
                  </p>
                  

                  
                    
              </div>
            
           
          <div class="grid3 contain2">
    
              <p style = {{fontSize: 15, marginLeft : 30}}>
                      <h3>  Comprehensive FDA 510(k) Cybersecurity Services   </h3>
                      
                      <p>
                      <b> Post-Market Cybersecurity Management Plans: </b> 
                      Developing a clear and actionable post-market strategy is a cornerstone of FDA compliance. TrustThink crafts Post-Market Cybersecurity 
                      Management Plans tailored to your device, addressing requirements for monitoring vulnerabilities, issuing timely updates, and mitigating emerging risks. 
                      </p>
          
            
                      <p>
                      <b> Cybersecurity Risk Assessments: </b> 
                      Identify, evaluate, and mitigate potential threats to your device with TrustThink’s risk assessment services. Our in-depth analyses align with FDA 
                      expectations, helping you establish a secure baseline and maintain device integrity throughout its lifecycle. 
                      </p>

                      <p>
                      <b> Software Bill of Materials (SBOM): </b> 
                      Transparency in your software supply chain is essential for regulatory compliance and security. TrustThink develops detailed SBOMs that catalog all software components, 
                      including third-party and open-source libraries. These SBOMs ensure vulnerabilities are identified and managed, reducing the risk of compromise. 
                      </p>

                      <p>
                      <b> Architectural Views and Threat Modeling: </b> 
                      Visualizing your device’s architecture and understanding its threat landscape are key to securing FDA approval. TrustThink provides detailed architectural 
                      views and conducts rigorous threat modeling, ensuring your submission demonstrates a strong cybersecurity posture. 
                      </p>

                      <p>
                      <b> Penetration Testing and Reporting: </b> 
                      Validate your device’s security through rigorous testing. TrustThink conducts penetration testing and vulnerability assessments to identify weaknesses, 
                      strengthen defenses, and deliver comprehensive reports for FDA submissions. 
                      </p>
                      

                </p>

                <p>
                <h4>  Why Choose Trustthink? </h4>
                      <div style = {{fontSize : 15}}>
                      <li>
                      <b> Proven Expertise: </b> 
                      Our team has extensive experience supporting medical device manufacturers through the FDA 510(k) submission process. 
                      </li>

                      <li>
                      <b> Regulatory Alignment: </b>
                      TrustThink aligns cybersecurity strategies with FDA guidance, ensuring your device meets or exceeds compliance standards. 
                      </li>

                      <li>
                      <b> Lifecycle Support: </b>
                      From pre-market risk assessments to post-market monitoring, we deliver end-to-end services that keep your devices secure. 
                      </li>

                      <li>
                      <b> Tailored Solutions: </b>
                      Every device is unique, and so is our approach. TrustThink designs customized plans and documentation that fit your specific needs. 
                      </li>
                      </div>
                     
                      
                    


              </p>
    </div>


            <div class="bottom">

              <a href="mailto:solutions@trustthink.net">
                          <strong>SOLUTIONS@TRUSTTHINK.NET</strong>
              </a>
              
            </div>

             {/* Back button */}
             <NavLink to="/cybersolutions/overview">
              <button type="button" class="back_btn">
                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>
            
          </div>

          

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          />
        </body>
        
        <Footer/>
      </Container>
    );
  }
}

export default CyberSolMedical;
