import React, { Component } from "react";
import Nav from "../Nav";
import MetaTags from "react-meta-tags";
import "./Industries_Home.css";
import Container from "../Container";
import { NavLink } from "react-router-dom";
import Footer from "../Footer";

class Industries extends Component {
  render() {
    return (
      <Container>
        <MetaTags>
          <meta
            id="meta-description"
            name="description"
            content="Explore TrustThink's work with leading companies in
                Healthcare, Transportation, and Defense."
          ></meta>
        </MetaTags>
        <Nav />
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester">

              <h2 class="wide">Industries</h2>
              <p class="opacity">
                <i></i>
              </p>
              <p class="lead highfont">
              TrustThink collaborates with leaders in the transportation, healthcare, and defense sectors to navigate the complex landscape of 
              modern technology, ensuring security and innovation go hand in hand.
              </p>
              <p class="opacity">
                <i></i>
              </p>
            </div>
          </div>
        </div>
        <div class="threeCardCentered">
          
            <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
               
                    <img class="rounded-t-lg transport_home_img" alt="Aerial view of freeway" />
          
                <div class="p-5">
                   
                        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-grey">Transportation</h5>
                  
                    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    TrustThink has developed standards and practices for the secure operation of connected and automated vehicles (CAV) and Intelligent Transportation Systems (ITS).
                    </p>
                    <NavLink to="/industries/transport"> 
                    <p class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                        Read more
                        <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </p>
                    </NavLink>
                </div>
            </div>

            

            

            
            <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
               
                    <img class="rounded-t-lg health_home_img" alt="Two surgeons" />
              
                <div class="p-5">
                  
                        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-grey">Healthcare</h5>
            
                    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    TrustThink drives secure innovation in healthcare, specializing in cybersecurity solutions for medical devices and health systems. We collaborate with healthcare providers 
                    and manufacturers to navigate complex regulatory landscapes, ensuring robust protection for critical health data and technologies
                    </p>
                    <NavLink to="/industries/healthcare"> 
                    <p class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                        Read more
                        <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </p>
                    </NavLink>
                </div>
            </div>

                      
            <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
             
                    <img class="rounded-t-lg def_home_img" alt="Aircraft engine manufacturing" />
           
                <div class="p-5">
                  
                        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-grey">Defense</h5>
               
                    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    TrustThink develops advanced cryptographic solutions and key management systems, setting standards for secure 
                    communications across the defense sector. We focus on enhancing the security frameworks necessary to protect sensitive defense communications and data. 
                    </p>
                    <NavLink to="/industries/defense"> 
                    <p class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                        Read more 
                        <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </p>
                    </NavLink>
                </div>
            </div>
        </div>
        <Footer/>

      </Container>
    );
  }
}

export default Industries;
