import React, { Component } from "react";
import Nav from "../Nav";
import "./About.css";
import Container from "../Container";
import "../Table.css";

import military from "../../img/military.png";
import scanner from "../../img/scanner.png";
import hacker from "../../img/hacker.png";
import doctor from "../../img/doctor.png";
import brain from "../../img/brain.png";
import car from "../../img/car.png";
import satelite from "../../img/satelite.png";
import truck from "../../img/truck.png";
import medical from "../../img/medical.png";

import Footer from "../Footer"


class About extends Component {
  render() {
    return (
      <Container>
        <Nav />
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">About TrustThink</h2>
              <p class="opacity">
                <i></i>
              </p>
              <p class="lead highfont">
                TrustThink was founded in 2018 with the goal of enabling a safe
                and secure future of connected and autonomous systems. We
                provide cyber security engineering for trusted IoT in the
                healthcare, transportation and defense industries.
              </p>
            </div>
          </div>
        </div>

        <div class="contain">
          <h id="header_text">Our Past Experience</h>

          <div class="grid8">
            <div class="imgitem">
              <div>
                <img class="militaryimg" alt="Military"></img>
              </div>
              <div>
                <h>
                  Strategic Roadmap for Cryptographic Modernization Across
                  Department of Defense
                </h>
              </div>
            </div>
            <div class="imgitem">
              <div>
                <img class="sateliteimg" alt="Satelite"></img>
              </div>
              <div>
                <h>
                  Key Management Design and Implementation for Satellite Mesh
                  Networks
                </h>
              </div>
            </div>
            <div class="imgitem">
              <div>
                <img class="doctorimg" alt="Doctor"></img>
              </div>

              <div>
                <h>
                  Security Architecture and Design Patterns for Connected
                  Medical Devices
                </h>
              </div>
            </div>
            <div class="imgitem">
              <div>
                <img class="truckimg" alt="Truck"></img>
              </div>
              <div>
                <h>
                  Pentration Test, Incident Response and Threat Models for
                  Automated Vehicles
                </h>
              </div>
            </div>

            <div class="imgitem">
              <div>
                <img class="hackerimg" alt="Hacker"></img>
              </div>
              <div>
                <h>Commercializing cryptography at scale (DARPA)</h>
              </div>
            </div>
            <div class="imgitem">
              <div>
                <img class="defense_img" alt="Unmanned aircraft"></img>
              </div>
              <div>
                <h>
                  Cyber security framework for uncrewed land, sea, and air
                  vehicles
                </h>
              </div>
            </div>

            <div class="imgitem">
              <div>
                <img class="brainimg" alt="logo"></img>
              </div>
              <div>
                <h>
                  Capability Maturity Model for Artificial Intelligence Adoption
                  in the Transportation Sector
                </h>
              </div>
            </div>

            <div class="imgitem">
              <div>
                <img class="carimg" alt="Car"></img>
              </div>
              <div>
                <h>
                  Security Configuration Guidance for Intelligent Transportation
                  Systems (ITS) Roadside Equipment
                </h>
              </div>
            </div>

            <div class="imgitem">
              <div>
                <img class="scannerimg" alt="Scanner"></img>
              </div>
              <div>
                <h>
                  Standard for Multi-Jurisdictional Cryptographic
                  Interoperability for Connected Vehicles and ITS Equipment
                </h>
              </div>
            </div>

            <div class="imgitem">
              <div>
                <img class="medicalimg" alt="Medical"></img>
              </div>
              <div>
                <h>
                  Attack Flows and Tabletop Guide for Robotic Assisted Surgical
                  (RAS) Systems
                </h>
              </div>
            </div>
          </div>

          <div class="slogan">
            <h>Connected. Intelligent. Secured.</h>
          </div>
        </div>
        <Footer/>
      </Container>
    );
  }
}

export default About;
