import React, { Component,useCallback, useEffect, useState } from 'react'
import Nav from "../Nav";
import "./News.css";
import Container from "../Container";
import "../Table.css";
import logo from "../../img/trustthinklogo.png";
import image1 from "../../img/codecar.jpg";
import brian from "../../img/brian.jpg";
import Footer from "../Footer"
import { NavLink } from "react-router-dom";
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { EmbedPDF } from "@simplepdf/react-embed-pdf";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;



const News = ({ post }) => {
  const [isMobile, setIsMobile] = useState(false)
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } 
  }
  const Report = () => {
   handleResize()
    
    // create an event listener

    if(isMobile === false){

    
    return (
    
      <div class="embed-responsive" style={{ width: "600px", height: "600px" }}>
      <embed
        src= {post.fullDescription}
        type="application/pdf"
        width="250px"
        height="50%"
      />
    </div>

   
    )
    }
    else if(isMobile === true)
      {
        
        window.location.href = post.fullDescription

        return (

          <div>
            Redirecting....

          </div>
        )

        
      }
  }

  if(post.tag != "report"){

  return (
    <Container>
      <Nav />
      <div class="pstimggrid">
        <div className="content-container">
          <div className="post">
            <div className="poster">
              <img className="profilepicture" src={post.pfp}></img>
              <div className="posttext">
                <b>{post.name}</b> <br></br>
                <div className="date">{post.date}</div>
              </div>
            </div>
            <div className="textbox2">
              <h4>{post.title}</h4>
              <p>
                {post.fullDescription}
                <div className="hashtag">{post.hashtags}</div>
              </p>
            </div>
          </div>
        </div>
        <img className="postimg" src={post.image}></img>
        
      </div>


       {/* Back button */}
       <NavLink to="/news">
              <button type="button" class="back_btn center">
                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>
            <br></br>
      <Footer/>
    </Container>
  );
}

else{

  return(
    <Container>
    <Nav />

    <div class="pstimggrid">
        <div className="content-container">
          <div className="post">
            <div className="poster">
              <div className="posttext">
                
                <div className="date">{post.date}</div>
              </div>
            </div>
            <h4>{post.title}</h4>
              <h5>{post.name}</h5>
            <div className="textbox2">
              
              <p>
                <Report className="textbox2" ></Report>
              </p>
            </div>
          </div>
        </div>
        <img className="postimg" src={post.image}></img>
        
      </div>


    



     {/* Back button */}
     <NavLink to="/news">
            <button type="button" class="back_btn center">
                          
              <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
              </svg>
                             
            </button>
          </NavLink>
          <br></br>
    <Footer/>
  </Container>
  )
}
};

export default News;