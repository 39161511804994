import React, { Component } from "react";

import "./HeroIndustries.css";
import Container from "../Container";
import { NavLink } from "react-router-dom";
import Footer from "../Footer"
class HeroIndustries extends Component {
  render() {
    return (
      <Container>
        <div className="main_marg1">
          <h1 className="toppadFIVE">Industries</h1>
          <p class=""></p>

          <div class="grid2">
            <div class="column2">
              <img
                alt="Artificial Intelligence Transportation"
                class="transportation_img1"
              ></img>

              <h4>Transportation</h4>
              <p>
              In an era defined by rapid technological evolution, the transportation industry is transforming. 
              As vehicles and infrastructure become increasingly interconnected....&nbsp;
                <div class="navl">
                  <NavLink to="/industries/transport">Learn More</NavLink>
                </div>
              </p>
            </div>
            <div class="column2">
              <img
                alt="Cloud services healthcare devices"
                class="healthcare_img"
              ></img>
              <h4>Healthcare</h4>
              <p>
              As healthcare technology advances, medical devices are increasingly connecting to hospital infrastructures and cloud services via cellular, 
              Wi-Fi, and Bluetooth. These advancements,while beneficial, introduce potential vulnerabilities that can compromise....&nbsp;
                <div class="navl">
                  <NavLink to="/industries/healthcare">Learn More</NavLink>
                </div>
              </p>
               
            </div>
            <div class="column2">
              <img alt="Umanned aircraft" class="defense_img"></img>
              <h4>Defense </h4>
              <p>
              The defense sector is undergoing a technological revolution, with the proliferation of unmanned systems and the increasing reliance on artificial intelligence and machine learning. 
                  These advancements are transforming...&nbsp;
                <div class="navl">
                  <NavLink to="/industries/defense">Learn More</NavLink>
                </div>
              </p>
            </div>
          </div>
        </div>
        <Footer/>
      </Container>
    );
  }
}

export default HeroIndustries;
