import React, { Component } from "react";

import "../../Table.css";
import Container from "../../Container"
import Footer from "../../Footer"
import { NavLink } from "react-router-dom";
import Nav from "../../Nav";

import vehicleImg from "../../../img/car3d.webp"

class ServiceConnectedVehicle extends Component {
  render() {
    return (
      <Container>
    <Nav />
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">Services </h2>
              <p class="opacity">
                <i></i>
              </p>
              <h1 class="lead highfont">
                Work with TrustThink to securely design, develop and deploy new
                technologies.
              </h1>
            </div>
          </div>
        </div>
        <body>
          <div class="grid10 addmargin">

          <div class="title">
          <h2>Connected Vehicle and ITS Security</h2>
            </div>

            <img class="service_images" src={vehicleImg} alt="Black and white car 3d" style= {{height: 400, width: 600}}/>

            <div class="imgtext">
          
              <p>
              <strong>The Current Environment:</strong> The integration of Intelligent Transportation Systems (ITS) and connected vehicles is rapidly advancing, leading to 
              increased complexities in cybersecurity. With the rise of connected infrastructure, ensuring the security of these systems is paramount to preventing disruptions and 
              ensuring safe operations. Governments and organizations are implementing standards to regulate and guide the secure development and deployment of these technologies.
              </p>

              <p>
              <strong>TrustThink's Experience:</strong> TrustThink has a strong background in enhancing the security of ITS and connected vehicles. We have collaborated with transportation authorities and 
              others to develop robust security frameworks that align with industry standards and regulatory requirements. Our hands-on experience with both the technology and the accompanying cybersecurity 
              challenges positions us uniquely to offer expert guidance and solutions.
              </p>

              <p>
              <strong>Security Assessments and Configuration Baselines:</strong> We provide comprehensive security assessments tailored to the specific needs of ITS infrastructure and connected vehicles. Our services 
              include creating configuration baselines that serve as a foundation for secure deployment, ensuring that all system components are protected against potential cybersecurity threats.
              </p>

              <p>
              <strong>Guidance on UNECE WP.29 R155 Compliance:</strong> For OEMs and Tier 1 suppliers, navigating the complexities of UNECE WP.29 R155 can be daunting. TrustThink offers expert guidance and compliance support
               to ensure that automotive manufacturers meet these international standards, helping them to implement necessary security measures effectively and maintain compliance as regulations evolve.
              </p>


            </div>
            <div class="bottom">
                Discover how TrustThink can empower your organization with leading-edge solutions tailored to the unique challenges of your industry. Contact us today to safeguard your technologies and propel your innovations forward.
               <p>
                <a href="mailto:solutions@trustthink.net">
                            <strong>SOLUTIONS@TRUSTTHINK.NET</strong>
                </a>
              </p>
              
              
            </div>
            {/* Back button */}
            <NavLink to="/services">
              <button type="button" class="back_btn">                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>

          </div>

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          />
        </body>
        <Footer/>
      </Container>
    );
  }
}

export default ServiceConnectedVehicle;
