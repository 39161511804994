import React, { Component } from "react";

import "../../Table.css";
import Container from "../../Container"
import Footer from "../../Footer"
import { NavLink } from "react-router-dom";
import autonomousImg from "../../../img/autonomoustesting.webp"
import Nav from "../../Nav";
class ServiceAutonomousTest extends Component {
  render() {
    return (
      <Container>
      <Nav />
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">Services </h2>
              <p class="opacity">
                <i></i>
              </p>
              <h1 class="lead highfont">
                Work with TrustThink to securely design, develop and deploy new
                technologies.
              </h1>
            </div>
          </div>
        </div>
        <body>
          <div class="grid10 addmargin">

          <div class="title">
          <h2>Autonomous Systems Security Testing</h2>
            </div>

            <img class="service_images" src={autonomousImg} alt="Digital lock" style= {{height: 400, width: 800}}/>

            <div class="imgtext">
          
              <p>
              <strong>The Growing Need for Robust Security in Autonomous Systems:</strong> As autonomous technologies rapidly advance, 
              particularly in defense and transportation, the imperative for rigorous security testing escalates. Vulnerabilities in 
              autonomous systems can lead to significant security threats, making thorough testing a crucial safeguard.
              </p>

              <p>
              <strong>TrustThink's Expertise in Action:</strong> TrustThink has been at the forefront of developing security testing 
              frameworks for autonomous systems, including automated commercial motor vehicles and unmanned aerial systems. 
              Our experience in crafting penetration test plans and compliance frameworks ensures that autonomous technologies not 
              only meet current security standards but are also prepared to counter future threats.
              </p>

              <p>
              <strong>Specialized Security Testing Services:</strong> Our services focus intensively on vulnerability assessments and 
              penetration testing specifically tailored for autonomous systems. Through these specialized services, we identify potential 
              security weaknesses and provide actionable insights to mitigate risks effectively.
              </p>

            

            </div>
            <div class="bottom ">
                Discover how TrustThink can empower your organization with leading-edge solutions tailored to the unique challenges of your industry. Contact us today to safeguard your technologies and propel your innovations forward.
              
              <p>
                <a href="mailto:solutions@trustthink.net">
                            <strong>SOLUTIONS@TRUSTTHINK.NET</strong>
                </a>
              </p>
              
              
            </div>
            {/* Back button */}
            <NavLink to="/services">
              <button type="button" class="back_btn">                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>

          </div>

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          />
        </body>
        <Footer/>
      </Container>
    );
  }
}

export default ServiceAutonomousTest;
