import React, { Component } from "react";
import "./Nav.css";
import Image from "../../img/BLACKlogo.png";
import { slide as Menu } from "react-burger-menu";

import { NavLink } from "react-router-dom";

{
  /* Links to files/pages are managed and created in components/App.js */
}
{
  /* Nav files are responsible for the creation of the navbar at the top of the website. */
}
class Nav extends Component {
  render() {
    return (
      <div id="nav_bar">
        <div class="display_none">{hamburger()}</div>
        <div id="nav_logo_div">
          <NavLink to="/">
            {" "}
            <img id="nav_logo" alt="logo"></img>
          </NavLink>
        </div>
        <div id="nav_list_items_div">
          <ul id="nav_list">
            
            <li className="nav_list_item">
      
                <div class="dropdown">
                  <NavLink to="/services">  
                    <b>Services</b>          
                    
                  </NavLink>
                  <div class="dropdown-content">
                    <div class="row my-4">
                      <div class="col-md-6 col-lg-3 mb-3 mb-lg-0">
                        <div class="list-group group-flush">
                          <NavLink to="/services/aiethics" > <strong> AI Ethics and Governance </strong></NavLink>
                          <NavLink to="/services/autonomoustest" ><strong>Autonomous Systems Security Testing</strong></NavLink>
                          <NavLink to="/services/blockchain" ><strong>Blockchain Security Consulting</strong></NavLink>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-3 mb-3 mb-lg-0">
                        <div class="list-group group-flush">
                          <NavLink to="/services/connectedvehicles" ><strong>Connected Vehicles and ITS Security</strong></NavLink>
                          <NavLink to="/services/encryptionkey" ><strong>Encryption and Key Management</strong></NavLink>
                          <NavLink to="/services/medicaldevices" ><strong>Medical Device Cybersecurity</strong></NavLink>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-3 mb-3 mb-md-0">
                        <div class="list-group list-group-flush">
                          <NavLink to="/services/quantum" ><strong>Quantum Computing Assessments</strong></NavLink>
                          <NavLink to="/services/incidentresponse" ><strong>Risk Management</strong></NavLink>
                          <NavLink to="/services/infrastructure" ><strong>Smart Infrastructure Security Solutions</strong></NavLink>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
     
            </li>
            <li className="nav_list_item">
              <NavLink to="/industries">
                <div class="dropdown">
                  <b>Industries</b>
                  <div class="dropdown-content">
                    <NavLink to="/industries/transport">
                      <li className="nav_list_item2">
                        <b>TRANSPORTATION</b>
                      </li>
                    </NavLink>
                    <NavLink to="/industries/healthcare">
                      <li className="nav_list_item2">
                        <b>HEALTHCARE</b>
                      </li>
                    </NavLink>
                    <NavLink to="/industries/defense">
                      <li className="nav_list_item2">
                        <b>DEFENSE</b>
                      </li>
                    </NavLink>
                  </div>
                </div>
              </NavLink>
            </li>
            <li className="nav_list_item">
              <NavLink to="/cybersolutions/overview">
                <div class="dropdown">
                  <b>Solutions</b>
                  <div class="dropdown-content">
                    <NavLink to="/cybersolutions/cmm">
                      <li className="nav_list_item2">
                        <b>AI CMM Workshops</b>
                      </li>
                    </NavLink>
                    <NavLink to="/cybersolutions/medical">
                      <li className="nav_list_item2">
                        <b>Medical Device Cybersecurity</b>
                      </li>
                    </NavLink>
                    <NavLink to="/cybersolutions/dronedefense">
                      <li className="nav_list_item2">
                        <b>Aerial Drone Cybersecurity</b>
                      </li>
                    </NavLink>
                  </div>
                </div>
              </NavLink>
            </li>
            <li className="nav_list_item">
              <NavLink to="/about">
                <b>About</b>
              </NavLink>
            </li>
            <li className="nav_list_item">
              <NavLink to="/news">
                <b>News</b>
              </NavLink>
            </li>
            <li className="nav_list_item" id="contact">
              {/* Pre tag maintains line breaks */}
              <b>
                <a href="mailto:solutions@trustthink.net">
                  <div>solutions@trustthink.net</div>
                </a>
              </b>
            </li>
          </ul>
        </div>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
        />
      </div>
    );
  }
}

export default Nav;

function hamburger() {
  return (
    <Menu
      width={"100%"}
      right
      pageWrapId={"page-wrap"}
      outerContainerId={"outer-container"}
    >
      <div id="nav_logo_div">
        <NavLink to="/">
          {" "}
          <img id="nav_logo" alt="logo"></img>
        </NavLink>
        
      </div>
      <ul id="nav_list">
        <NavLink to="/services">
          <li className="nav_list_item">
            <b>SERVICES </b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
              
            </span>
          </li>
        </NavLink>
        <NavLink to="/services/aiethics">
          <li className="nav_list_item2">
            <b>AI ETHICS AND GOVERNANCE</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
            </span>
          </li>
        </NavLink>
        <NavLink to="/services/autonomoustest">
          <li className="nav_list_item2">
            <b>AUTONOMOUS SYSTEMS SECURITY TESTING</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
            </span>
          </li>
        </NavLink>
        <NavLink to="/services/blockchain">
          <li className="nav_list_item2">
            <b>BLOCKCHAIN SECURITY CONSULTING</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
            </span>
          </li>
        </NavLink>
        <NavLink to="/services/connectedvehicles">
          <li className="nav_list_item2">
            <b>CONNECTED VEHICLES AND ITS SECURITY</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
            </span>
          </li>
        </NavLink>
        <NavLink to="/services/encryptionkey">
          <li className="nav_list_item2">
            <b>ENCRYPTION AND KEY MANAGEMENT</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
            </span>
          </li>
        </NavLink>
        <NavLink to="/services/medicaldevices">
          <li className="nav_list_item2">
            <b>MEDICAL DEVICE CYBERSECURITY</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
            </span>
          </li>
        </NavLink>
        <NavLink to="/services/quantum">
          <li className="nav_list_item2">
            <b>QUANTUM COMPUTING ASSESSMENTS</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
            </span>
          </li>
        </NavLink>
        <NavLink to="/services/incidentresponse">
          <li className="nav_list_item2">
            <b>RISK MANAGEMENT</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
            </span>
          </li>
        </NavLink>
        <NavLink to="/services/infrastructure">
          <li className="nav_list_item2">
            <b>SMART INFRASTRUCTURE SECURITY SOLUTIONS</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
            </span>
          </li>
        </NavLink>
        <NavLink to="/industries">
          <li className="nav_list_item">
            <b>INDUSTRIES</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
              
            </span>
          </li>
        </NavLink>
        <NavLink to="/industries/transport">
          <li className="nav_list_item2">
            <b>TRANSPORTATION</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">

            </span>
          </li>
        </NavLink>

        <NavLink to="/industries/healthcare">
          <li className="nav_list_item2">
            <b>HEALTHCARE</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
              
            </span>
          </li>
        </NavLink>

        <NavLink to="/industries/defense">
          <li className="nav_list_item2">
            <b>DEFENSE</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
              
            </span>
          </li>
        </NavLink>
        <NavLink to="/cybersolutions/overview">
          <li className="nav_list_item">
            <b>SOLUTIONS</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
              
            </span>
          </li>
        </NavLink>
        <NavLink to="/cybersolutions/cmm">
          <li className="nav_list_item2">
            <b>AI CMM WORKSHOPS</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
              
            </span>
          </li>
        </NavLink>
        <NavLink to="/cybersolutions/medical">
          <li className="nav_list_item2">
            <b>MEDICAL DEVICE CYBERSECURITY</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
              
            </span>
          </li>
        </NavLink>
        <NavLink to="/cybersolutions/dronedefense">
          <li className="nav_list_item2">
            <b>AERIAL DRONE CYBERSECURITY</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
              
            </span>
          </li>
        </NavLink>
        <NavLink to="/about">
          <li className="nav_list_item3">
            <b>ABOUT</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
              
            </span>
          </li>
        </NavLink>
        <NavLink to="/news">
          <li className="nav_list_item">
            <b>NEWS</b>
            <span class="navarrow        material-symbols-outlined arrowbutton">
              
            </span>
          </li>
        </NavLink>
        <li className="nav_list_item" id="contact">
          {/* Pre tag maintains line breaks */}
          <b>
            <a href="mailto:solutions@trustthink.net">
              <div>solutions@trustthink.net</div>
            </a>
          </b>
        </li>
      </ul>
    </Menu>
  );
}























