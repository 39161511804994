import React, { Component } from "react";
import Nav from "../../Nav";
import "../../Table.css";
import Container from "../../Container";
import graph from "../../../img/scanner.png";
import "./SecuredVehicles.css";

class SecuredVehicles extends Component {
  render() {
    return (
      <Container>
        <Nav />

        <div class="textbox">
          <img src={graph}></img>
          <h4>Secure Automated Commercial Motor Vehicles</h4>

          <p>
            Commercial Motor Vehicles (CMVs) are the backbone of our shipping
            industry. CMVs already integrate multiple communication interfaces
            including cellular, satellite, Wi-Fi and Bluetooth to exchange data
            internally, with the cloud, and with FMS telematics. Advanced Driver
            Assistance System (ADAS) technologies are now being integrated into
            vehicles to enable automated emergency breaking, predictive
            powertrain control, lane keep assist, traffic signals recognition
            and more capabilities. These technologies rely on hardware (cameras,
            radar, lidar, acoustic sensors, engine control units, etc), software
            (mapping, sensor fusion, etc) and algorithms (object tracking,
            obstacle avoidance, markings recognition, etc) to operate
            effectively. ADAS-enabled heavy trucks are on our roadways today and
            will be for many years as the automotive industry continues slowly
            towards level 5 automation. 
          </p>
          <p>
            ADAS-enabled vehicles and the systems they communicate with need to
            be developed and operated securely. Vehicles need secure interfaces,
            segmented busses, firewalls and whitelisting that restrict access to
            safety critical functions, etc. After-market ADAS components should
            go through a security assessment prior to integration to validate
            that they adhere to basic security controls, and interfaces to fleet
            management systems should be authenticated, encrypted, and filtered.
          </p>
          <p>
            There are already many great resources for understanding threats to
            CMVs and recommended security controls. Some of these include: 
          </p>
          <ul class="ulmarg">
            <li class=" ">
              <b>Cybersecurity Research Considerations for Heavy Vehicles</b>{" "}
              (available at:{" "}
              <a href="https://rosap.ntl.bts.gov/view/dot/38822">
                https://rosap.ntl.bts.gov/view/dot/38822
              </a>
              )
            </li>
            <li class=" ">
              <b>
                Cybersecurity Best Practices for Integration/Retrofit of
                Telematics and Aftermarket Electronic Systems into Heavy
                Vehicles
              </b>{" "}
              (available at:{" "}
              <a href="https://rosap.ntl.bts.gov/view/dot/49248">
                https://rosap.ntl.bts.gov/view/dot/49248
              </a>
              )
            </li>
            <li class=" ">
              <b>
                Secure Device Provisioning Best Practices: Heavy Truck Edition
              </b>{" "}
              (available at:{" "}
              <a href="https://www.nccgroup.trust/globalassets/us-web-images/nick/secure-device-provisioning.pdf">
                https://www.nccgroup.trust/globalassets/us-web-images/nick/secure-device-provisioning.pdf
              </a>
              )
            </li>
            <li class=" ">
              <b>
                ENISA Cybersecurity Challenges in the Uptake of Artificial
                Intelligence in Autonomous Driving
              </b>{" "}
              (available at:{" "}
              <a href="https://www.enisa.europa.eu/publications/enisa-jrc-cybersecurity-challenges-in-the-uptake-of-artificial-intelligence-in-autonomous-driving/">
                https://www.enisa.europa.eu/publications/enisa-jrc-cybersecurity-challenges-in-the-uptake-of-artificial-intelligence-in-autonomous-driving/
              </a>
              )
            </li>
            <br></br>
            <p class="pmarg"> 
              A few industry organizations are also doing great work to help
              stakeholders secure their vehicles and fleets. These include:
            </p>
            <li class=" ">
              <b>Auto ISAC</b> (available at:{" "}
              <a href="https://automotiveisac.com/">
                https://automotiveisac.com/
              </a>
              )
            </li>
            <li class=" ">
              <b>NMFTA</b> (available at:{" "}
              <a href="http://www.nmfta.org/pages/CTSRP">
                http://www.nmfta.org/pages/CTSRP
              </a>
              )
            </li>
            <li class=" ">
              <b>ATA TMC</b> (available at:{" "}
              <a href="https://tmc.trucking.org/">https://tmc.trucking.org/</a>)
            </li>
          </ul>
          <br></br>
          <p>
            There is still more research required though. Adversarial examples
            are one area, as researchers have already shown it is possible to
            trick cameras into seeing something other than what a human sees.
            Human-machine interaction is another interesting area for research.
            This includes understanding optimal times to handoff driver
            responsibilities from the vehicle to the driver in case of an
            electronic attack or other potentially hazardous situation, and vice
            versa. 
          </p>
        </div>
      </Container>
    );
  }
}

export default SecuredVehicles;
