import React from "react";
import { NavLink } from "react-router-dom";

const ButtonLearnMore = ({buttonLink}) => {
    return(
        <NavLink to = {buttonLink}> 
        <p class="inline-flex px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                          Learn more
                        <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 5">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
        </p>
        </NavLink>
    );
};

export default ButtonLearnMore