import React, { Component } from "react";
import Nav from "../Nav";
import "./Resources.css";
import Container from "../Container";
import { NavLink } from "react-router-dom";

import cis from "./cis.jpg";
import csa1 from "./csa1.png";
import csa2 from "./csa2.png";
import csa3 from "./csa3.png";
import piot2 from "./piot2.jpg";
import sia from "./sia.jpg";
import iiot from "./IIOT.jpg";

class Resources extends Component {
  render() {
    return (
      <Container>
        <div id="cybersol_div">
          <Nav />
        </div>
        <div class="headerCenter">
          <h1>Books</h1>
        </div>
        <div class="containerBox">
          <div class="boxerNew autowidth">
            <article>
              <h2>
                <a
                  class="smallsmall"
                  href="https://www.amazon.com/Practical-Internet-Things-Security-framework/dp/178862582X"
                  title=""
                  target="_blank"
                  onclick="var otherWindow = window.open(); otherWindow.opener = null; otherWindow.location = href; return false;"
                >
                  Practical Internet of Things Security
                </a>
              </h2>

              <img class="padIMG" src={iiot} alt="" align="left" />
              <div class="fontSizeP">
                <p>
                  {" "}
                  A practical, indispensable security guide that will navigate
                  you through the complex realm of securely building and
                  deploying systems in our IoT-connected world{" "}
                </p>
              </div>
            </article>
          </div>

          <div class="boxerNew autowidth">
            <article>
              <h2>
                <a
                  class="smallsmall"
                  href="https://www.amazon.com/Intelligent-Internet-Things-Device-Cloud/dp/3030303667/"
                  title=""
                  target="_blank"
                  onclick="var otherWindow = window.open(); otherWindow.opener = null; otherWindow.location = href; return false;"
                >
                  Intelligent Internet of Things
                </a>
              </h2>
              <img class="padIMG" src={piot2} alt="test" align="left"></img>
              <div class="fontSizeP">
                <p>
                  {" "}
                  This holistic book is an invaluable reference for addressing
                  various practical challenges in architecting and engineering
                  Intelligent IoT and eHealth solutions for industry
                  practitioners, academic and researchers, as well as for
                  engineers involved in product development.
                </p>
              </div>
            </article>
          </div>
        </div>

        <div>
          <div class="headerCenter">
            <h1>Standards & Guidances</h1>
            <div class="pCenter"></div>
          </div>
        </div>

        <div class="containerBox">
          <div class="boxerNew autowidth">
            <article>
              <h2>
                <a
                  class="smallsmall"
                  href="https://cloudsecurityalliance.org/artifacts/future-proofing-the-connected-world/"
                  title=""
                  target="_blank"
                  onclick="var otherWindow = window.open(); otherWindow.opener = null; otherWindow.location = href; return false;"
                >
                  Future Proofing the Connected World
                </a>
              </h2>
              <img class="padIMG" src={csa1} alt="" align="left" />
              <div class="fontSizeP">
                <p>
                  A secure development framework for new connected devices and
                  systems.
                </p>
              </div>
            </article>
          </div>

          <div class="boxerNew autowidth">
            <article>
              <h2>
                <a
                  class="smallsmall"
                  href="https://cloudsecurityalliance.org/artifacts/establishing-a-safe-and-secure-municipal-drone-program/"
                  title=""
                  target="_blank"
                  onclick="var otherWindow = window.open(); otherWindow.opener = null; otherWindow.location = href; return false;"
                >
                  Establishing a Safe and Secure Municipal Drone Program
                </a>
              </h2>
              <img class="padIMG" src={csa2} alt="" align="left" />
              <div class="fontSizeP">
                <p>
                  {" "}
                  A plan of action for implementing a municipal drone program
                  securely.
                </p>
              </div>
            </article>
          </div>

          <div class="boxerNew autowidth">
            <article>
              <h2>
                <a
                  class="smallsmall"
                  href="https://cloudsecurityalliance.org/download/artifacts/iot-security-controls-framework/"
                  title=""
                  target="_blank"
                  onclick="var otherWindow = window.open(); otherWindow.opener = null; otherWindow.location = href; return false;"
                >
                  Internet of Things Security Control Framework
                </a>
              </h2>
              <img class="padIMG" src={csa3} alt="" align="left" />
              <div class="fontSizeP">
                <p>
                  {" "}
                  A comprehensive planning guide for enterprise organizations to
                  secure their IoT systems
                </p>
              </div>
            </article>
          </div>
          <div class="boxerNew autowidth">
            <article>
              <h2>
                <a
                  class="smallsmall"
                  href="https://www.cisecurity.org/white-papers/internet-of-things-security-companion-to-the-cis-critical-security-controls/"
                  title=""
                  target="_blank"
                  onclick="var otherWindow = window.open(); otherWindow.opener = null; otherWindow.location = href; return false;"
                >
                  CIS Controls Internet of Things Companion Guide
                </a>
              </h2>
              <img class="padIMG" src={cis} alt="" align="left" />
              <div class="fontSizeP">
                <p>
                  This guide tailors the widely adopted CIS 20 Critical Security
                  Controls to the Internet of Things domain.
                </p>
              </div>
            </article>
          </div>

          <div class="boxerNew autowidth">
            <article>
              <h2>
                <a
                  class="smallsmall"
                  href="https://www.securityindustry.org/report/challenges-and-recommendations-for-connected-physical-security-products/"
                  title=""
                  target="_blank"
                  onclick="var otherWindow = window.open(); otherWindow.opener = null; otherWindow.location = href; return false;"
                >
                  Security Industry Association
                </a>

                <div class="fontSmall">
                  <p>
                    Challenges and Recommendations for Connected Physical
                    Security Products
                  </p>
                </div>
              </h2>

              <img class="padIMG" src={sia} alt="" align="left" />
              <div class="fontSizeP">
                <p>
                  This guide focuses on best practices for physical security
                  device manufacturers to secure their newly Internet-connected
                  products.
                </p>
              </div>
            </article>
          </div>
        </div>
      </Container>
    );
  }
}

export default Resources;
