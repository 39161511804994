import React, { Component } from "react";

import "../Table.css";
import { NavLink } from "react-router-dom";
import Container from "../Container";
import "./TableListSec.css";
import Accordion from "../Accordion";
import LearnMoreButton from "../ButtonLearnMore"

import aiImg from "../../img/aiethics.webp"
import autonomousImg from "../../img/autonomoustesting.webp"
import blockchainImg from "../../img/blockchain.webp"
import connectedvehiclesImg from "../../img/car3d.webp"
import encryptionImg from "../../img/encryptionimg-md.webp"
import incidentresponseImg from "../../img/incident.webp"
import medicaldevicesImg from "../../img/medicalDevices.webp"
import quantumImg from "../../img/quantum.webp"
import infrastructureImg from "../../img/infrastructure.webp"



class TableListSec extends Component {




  render() {
    return (
      <Container>
        <body>
          <section class="showcase addmargin">

            <div class="grid3">
         
              <ul>
                <h2> AI Ethics and Governance </h2>
                <p>
                  Consulting on the development of ethical guidelines and governance frameworks for AI 
                  deployments in sensitive sectors like healthcare, transportation and defense.
                </p>
                <LearnMoreButton buttonLink="/services/aiethics"></LearnMoreButton>
              </ul>

              <img class="images" src={aiImg} alt="Neural networks" style= {{height: 200, width: 500}}/>
         


            <ul>
              <h2> Autonomous Systems Security Testing </h2>
              <p>
                Specialized security testing services for autonomous systems in defense and transportation, 
                focusing on vulnerability assessments and penetration testing.
              </p>
              <LearnMoreButton buttonLink="/services/autonomoustest"/>

            </ul>

            <img class="images" src={autonomousImg} alt="Neural networks" style= {{height: 200, width: 500}}/>

            <ul>
              <h2> Blockchain Security Consulting </h2>
              <p>
                Offering expertise on securing and enabling blockchain applications in transportation and logistics.
              </p>
              <LearnMoreButton buttonLink="/services/blockchain"/>

            </ul>

            <img class="images" src={blockchainImg} alt="Neural networks" style= {{height: 200, width: 500}}/>

            <ul>
              <h2> Connected Vehicles and ITS Security </h2>
            
                Security assessments and configuration baselines for ITS infrastructure and connected vehicles. 
                Guidance on UNECE WP.29 R155 compliance for OEMs and Tier 1 suppliers. 
                <br></br>
                <LearnMoreButton buttonLink="/services/connectedvehicles"/>

             

            </ul>

            <img class="images" src={connectedvehiclesImg} alt="Neural networks" style= {{height: 200, width: 500}}/>

            <ul>
              <h2> Encryption and Key Management Architectures </h2>
              <p>
                 Developing Key and Certificate Management Plans  
                 Integrating secure key management and encryption solutions into modern technologies. 
              </p>
              <LearnMoreButton buttonLink="/services/encryptionkey"/>

            </ul>

            <img class="images" src={encryptionImg} alt="Neural networks" style= {{height: 200, width: 500}}/>

            <ul>
              <h2> Incident Response and Risk Management </h2>
              <p>
                 Custom drills and tabletop exercises for effective crisis management.  
                 Comprehensive risk management planning to protect critical infrastructure. 
              </p>
              <LearnMoreButton buttonLink="/services/incidentresponse"/>

            </ul>

            <img class="images" src={incidentresponseImg} alt="Neural networks" style= {{height: 200, width: 500}}/>

            <ul>
              <h2> Medical Device Cybersecurity and Compliance </h2>
              <p>
                 Tailored security assessments and documentation to meet FDA 510(k) requirements.  
                 Secure integration practices for IoT-enabled medical devices. 
              </p>
              <LearnMoreButton buttonLink="/services/medicaldevices"/>

            </ul>

            <img class="images" src={medicaldevicesImg} alt="Neural networks" style= {{height: 200, width: 500}}/>

            <ul>
              <h2> Quantum Computing Impact Assessments </h2>
              <p>
                Evaluating the potential risks and opportunities presented by quantum computing advancements, 
                particularly in terms of cryptographic security.
              </p>
              <LearnMoreButton buttonLink="/services/quantum"/>

            </ul>

            <img class="images" src={quantumImg} alt="Neural networks" style= {{height: 200, width: 500}}/>

            <ul>
              <h2> Smart Infrastructure Security Solutions </h2>
              <p>
                Providing security solutions for smart city projects, including IoT security, data protection, 
                and compliance with smart city standards.
              </p>
              <LearnMoreButton buttonLink="/services/infrastructure"/>

            </ul>
            

            <img class="images" src={infrastructureImg} alt="Neural networks" style= {{height: 200, width: 500}}/>


          

            </div>

            
          </section>
          <div class="bottom">
           
              Discover how TrustThink can empower your organization with leading-edge solutions tailored to the unique challenges of your industry. Contact us today to safeguard your technologies and propel your innovations forward.
              <br></br><br></br>
              <a href="mailto:solutions@trustthink.net">
                          <strong>SOLUTIONS@TRUSTTHINK.NET</strong>
              </a>
              
            </div>

            
          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
        </body>
      </Container>
    );
  }
}

export default TableListSec;
