import React, { Component } from "react";
import Nav from "../Nav";
import "./ServiceMedical.css";
import Container from "../Container";
import think from "../images/think.png";
import TableListHealth from "../TableListHealth";
class ServiceMedical extends Component {
  render() {
    return (
      <Container>
        <div id="cybersol_div">
          <Nav />
        </div>
        <div className="main_marg centerr">
          <h1 className="toppadTWO mobileh1">
            Medical Device Cyber Security for HDOs
          </h1>
          <h4 className="mobileh4">
            Contact Us for On-Demand Cyber Security Engineering Services{" "}
          </h4>
          <a href="tel:619-804-8840">619-804-8840</a>
        </div>

        <p></p>

        <TableListHealth />
        <br></br>
        <br></br>
        <div className="main_marg leadSmall">
          <h4>TrustThink Offers: </h4>
          <br></br>
          <h6>Risk Analysis and Device Onboarding Process</h6>
          <p>
            Put in place an enterprise risk analysis process to evaluate medical
            device security before allowing devices onto your networks. Use
            checklists such as MDS2 combined with targeted penetration testing
            to evaluate the security posture of devices and work with vendors to
            remediate vulnerabilities. Understand your residual risk and
            implement compensating controls to mitigate vulnerabilities prior to
            onboarding.
          </p>

          <br></br>
          <h6>Medical Device Threat Scoring and Prioritization </h6>
          <p>
            Today’s medical devices are deployed within complex systems that
            include cloud services, device-to-device communication, remote
            access and monitoring. Threats to traditional medical devices are
            different than threats to new wearables, implantables and robotics.
            TrustThink can provide a process for modeling threats that
            incorporates patient safety and clinical impacts. Our process
            integrates the Common Vulnerability Scoring System (CVSS), Common
            Weakness Scoring System (CWSS), the Common Weaknesses Risk Analysis
            Framework (CWRAF) and the MITRE ATT&CK Framework to score threats
            based on an FDA approved rubric. The end result is a threat scoring
            process that allows you to prioritize both threats and associated
            mitigations.
          </p>

          <br></br>
          <h6>Medical Device Security Monitoring </h6>
          <p>
            One of the biggest challenges associated with medical devices is
            maintaining visibility and situational awareness. TrustThink can
            provide a roadmap to asset management and continuous monitoring of
            your medical devices, including devices that are not attached
            directly to the network. Visibility should also include an
            understanding of the libraries embedded within your devices.
            Monitoring should incorporate behavior-based analysis by defining
            baseline behaviors and watching for anomalies.
          </p>

          <br></br>
          <h6>Evolve to a Zero Trust Architecture</h6>
          <p>
            Plan to evolve from today’s isolation architectures to a Zero Trust
            Architectural approach for IP connected medical devices. Understand
            which devices have appropriate roots of trust (RoT) to assure
            authentication decisions to the network and design an architecture
            that includes authorization, continuous monitoring, software defined
            networking and behavior-based anomaly detection.
          </p>

          <br></br>
          <h6>Incident Response Planning</h6>
          <p>
            Work with us to define an incident response process that
            incorporates clinical considerations and patient safety impacts.
            Understand what you need to do to prepare, detect, contain,
            eradicate, and recover from incidents.
          </p>
        </div>

      </Container>
    );
  }
}

export default ServiceMedical;
