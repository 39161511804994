import React, { Component } from "react";

import "../Table.css";
import Container from "../Container";
class TableListCrypto extends Component {
  render() {
    return (
      <Container>
        <body>
          <section class="showcase">
            <div class="container-fluid p-0 ">
              <div class="row no-gutters">
                <div class="col-lg-5 order-lg-1 text-white showcase-img picture4">
                  <span></span>
                </div>
                <div class="col-lg-5 my-auto order-lg-1 showcase-text">
                  <p class="lead mb-0">
                    TrustThink works with some of the largest organizations in
                    the world to modernize cryptography and implement scalable
                    cryptographic key management practices. Our engineers have
                    deep expertise in Public Key Infrastructure (PKI), Virtual
                    Private Networks (VPNs) and cryptographic protocols. Work
                    with TrustThink to develop key management plans and
                    architectures, move to quantum-resistant crypto, and to
                    integrate strong cryptography into your Internet of Things
                    (IoT) device developments.
                  </p>
                </div>
              </div>
              <br></br>
            </div>
          </section>
          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
        </body>
      </Container>
    );
  }
}

export default TableListCrypto;
