import React, { Component } from "react";

import "./HeroServices.css";
import Container from "../Container";
import { NavLink } from "react-router-dom";
import HeroIndustries from "../HeroIndustries";
import { HashLink } from "react-router-hash-link";
import Carousel from "../Carousel/Carousel";


class About extends Component {
  render() {
    return (
      <Container>
        <div class="main_marg1">
          <h1 className="toppadFIVE">Services</h1>
          <p class="">
            TrustThink provides services and solutions that secure connected and
            autonomous vehicles, satellite systems, medical devices, robotics
            and drones. We work with customers in Defense, National Security,
            Transportation and Healthcare to realize the vision of a safe and
            secure connected world.
          </p>

          <Carousel/>
        </div>
        <HeroIndustries></HeroIndustries>
      </Container>
    );
  }
}

export default About;
