import React, { Component } from "react";

import "../../Table.css";
import Container from "../../Container"
import Footer from "../../Footer"
import { NavLink } from "react-router-dom";
import medicaldevicesImg from "../../../img/medicalDevices.webp"
import Nav from "../../Nav";
class ServiceMedicalDevices extends Component {
  render() {
    return (
      <Container>
      <Nav />
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">Services </h2>
              <p class="opacity">
                <i></i>
              </p>
              <h1 class="lead highfont">
                Work with TrustThink to securely design, develop and deploy new
                technologies.
              </h1>
            </div>
          </div>
        </div>
        <body>
          <div class="grid10 addmargin">

          <div class="title">
          <h2>Medical Device Cybersecurity Compliance</h2>
            </div>

            <img class="service_images" src={medicaldevicesImg} alt="Digital Network image" style= {{height: 400, width: 800}}/>

            <div class="imgtext">
          
              <p>
              <strong>Ensuring Compliance in a Dynamic Regulatory Environment:</strong> The rapid integration of IoT technology into medical devices has introduced 
                significant security challenges, necessitating stringent cybersecurity measures. Regulatory bodies like the FDA demand comprehensive cybersecurity 
                documentation to ensure patient safety and data security.
              </p>

              <p>
              <strong>TrustThink's Expertise and Solutions:</strong> At TrustThink, we specialize in providing tailored security assessments and detailed compliance documentation that 
                align with FDA 510(k) requirements. Our experience spans across various medical device categories, ensuring that each device meets the highest standards of cybersecurity.
              </p>

              <p>
              <strong>Tailored Security Assessments:</strong> Our security assessments are specifically designed to identify vulnerabilities in medical devices and suggest robust mitigation strategies. 
              These assessments help manufacturers navigate the complex requirements for FDA approval, ensuring all security aspects are thoroughly evaluated and documented.
              </p>

              <p>
              <strong>Secure Integration Practices for IoT-Enabled Medical Devices:</strong> We also focus on the secure integration of IoT capabilities into medical devices. Our approach ensures that 
              these devices not only comply with regulatory standards but are also resilient against evolving cyber threats. This includes everything from secure communication protocols to data encryption 
              and access controls, providing a comprehensive security framework for IoT-enabled healthcare solutions.
              </p>


            </div>
            <div class="bottom">
                Discover how TrustThink can empower your organization with leading-edge solutions tailored to the unique challenges of your industry. Contact us today to safeguard your technologies and propel your innovations forward.
               <p>
                <a href="mailto:solutions@trustthink.net">
                            <strong>SOLUTIONS@TRUSTTHINK.NET</strong>
                </a>
              </p>
              
              
            </div>
            {/* Back button */}
            <NavLink to="/services">
              <button type="button" class="back_btn">                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>

          </div>

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          />
        </body>
        <Footer/>
      </Container>
    );
  }
}

export default ServiceMedicalDevices;
