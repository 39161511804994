import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import CyberSec from "./components/CyberSec";
import ServiceEncryption from "./components/ServiceEncryption";
import Industries from "./components/Industries";
import Transportation from "./components/Industries/Transportation";
import Healthcare from "./components/Industries/Healthcare";
import Defense from "./components/Industries/Defense";
import Resources from "./components/Resources";
import About from "./components/About";
import Container from "./components/Container";
import Hero from "./components/Hero";
import News from "./components/News";
import NewsTable from "./components/NewsTable/NewsTable";
import { data } from "./data/postsData.jsx";

import CyberSol from "./components/TableListSol";
import CyberSolCMM from "./components/CyberSolCMM";
import CyberSolMedical from "./components/CyberSolMedical"
import CyberSolDrone from "./components/CyberSolDrone"



import ServiceMedical from "./components/ServiceMedical";
import ServiceCommercialMV from "./components/ServiceCommercialMV";
import ServiceCryptography from "./components/ServiceCryptography";

import ServiceConnectedVehicle from "./components/Services/ServiceConnectedVehicle/ServiceConnectedVehicle.jsx";
import ServiceEncryptionKey from "./components/Services/ServiceEncryptionKey/ServiceEncryptionKey.jsx";
import ServiceQuantum from "./components/Services/ServiceQuantum/ServiceQuantum.jsx";
import ServiceAIEthics from "./components/Services/ServiceAIEthics/ServiceAIEthics.jsx";
import ServiceInfrastructure from "./components/Services/ServiceInfrastructure/ServiceInfrastructure.jsx";
import ServiceAutonomousTest from "./components/Services/ServiceAutonomousTest/ServiceAutonomousTest.jsx";
import ServiceBlockchain from "./components/Services/ServiceBlockchain/index.jsx";
import ServiceIncidentResponse from "./components/Services/ServiceIncidentResponse/ServiceIncidentResponse.jsx";
import ServiceMedicalDevices from "./components/Services/ServiceMedicalDevices/ServiceMedicalDevice.jsx";

import RASTableTop from "./components/Industries/RASTableTop";
import UncrewedVehicles from "./components/Industries/UncrewedVehicles";
import SecuredVehicles from "./components/Industries/SecuredVehicles";

import ScrollIntoView from "./components/ScrollIntoView";
import ScrollToTop from "./components/ScrollToTop.js";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop/>
          <Switch>
            <Route path="/industries/transport" component={Transportation} />
            <Route path="/industries/uncrewed" component={UncrewedVehicles} />
            <Route
              path="/industries/securedvehicles"
              component={SecuredVehicles}
              
            />
            <Route
              path="/industries/healthcare/rastabletop"
              component={RASTableTop}
            />
            <Route path="/industries/healthcare" component={Healthcare} />
            <Route path="/industries/defense" component={Defense} />
            <Route path="/industries" component={Industries} />
            <Route path="/cybersolutions/cmm" component={CyberSolCMM} />
            <Route path="/cybersolutions/medical" component={CyberSolMedical} />
            <Route path="/cybersolutions/dronedefense" component={CyberSolDrone} /> 

            <Route path="/cybersolutions/overview" component={CyberSol} />
            <Route path="/services/medical" component={ServiceMedical} />
            <Route path="/encryptionservice" component={ServiceEncryption} />
            <Route
              path="/services/commercialvehicles"
              component={ServiceCommercialMV}
            />
            <Route
              path="/services/cryptography"
              component={ServiceCryptography}
            />

            <Route path="/services/connectedvehicles" component={ServiceConnectedVehicle}/>
            <Route path="/services/encryptionkey" component={ServiceEncryptionKey}/>
            <Route path="/services/quantum" component={ServiceQuantum}/>
            <Route path="/services/aiethics" component={ServiceAIEthics}/>
            <Route path="/services/infrastructure" component={ServiceInfrastructure}/>
            <Route path ="/services/autonomoustest" component={ServiceAutonomousTest}/>
            <Route path ="/services/blockchain" component={ServiceBlockchain}/>
            <Route path ="/services/incidentresponse" component={ServiceIncidentResponse}/>
            <Route path ="/services/medicaldevices" component={ServiceMedicalDevices}/>


            <Route path="/services" component={CyberSec} />
           
           

            <Route path="/resources" component={Resources} />
            <Route path="/about" component={About} />
            <Route path="/news" component={NewsTable} />
            <Route
              path="/post/:id"
              render={({ match }) => {
                const postId = match.params.id;
                const post = data.find((post) => post.id === parseInt(postId));
                return <News post={post} />;
              }}
            />
            <Route path="/" component={Hero} />
        
          </Switch>
   

        <Container></Container>
      </BrowserRouter>
    );
  }
}

export default App;
