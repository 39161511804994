import React, { Component } from "react";
import Nav from "../Nav";
import "./NewsTable.css";
import Container from "../Container";
import { NavLink } from "react-router-dom";
import { data } from "../../data/postsData.jsx";
import Footer from "../Footer"

const CustomTextbox = ({
  id,
  title,
  description,
  hashtags,
  image,
  date,
  pfp,
  name,
  link,
}) => {
  return (
    <div class="rectcontainer">
      <NavLink to={`/post/${id}`}>
        <div class="text1">
          <div class="postgrid">
            <img className="postcontainer" src={image}></img>
            <div class="textanddescription">
              <h4>{title}</h4>
              {description}
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  );
};

const EmptyTextBox = ({}) => {
  return (
    <div class="rectcontainer2">
      <div class="text1">
        <div class="postgrid">
          <div class="textanddescription">
            <h4></h4>
          </div>
        </div>
      </div>
    </div>
  );
};

const CounterList = ({}) => {
  var latestnews = [];
  var insights = [];

  for (var i in data) {
    if (data[i].type === "latestnews") {
      latestnews.push(
        <CustomTextbox
          id={data[i].id}
          description={data[i].shortDescription}
          title={data[i].title}
          image={data[i].image}
        ></CustomTextbox>
      );
    }
    if (data[i].type === "insights") {
      insights.push(
        <CustomTextbox
          id={data[i].id}
          description={data[i].shortDescription}
          title={data[i].title}
          image={data[i].image}
        ></CustomTextbox>
      );
    }
  }

  while (insights.length < latestnews.length) {
    insights.push(<EmptyTextBox></EmptyTextBox>);
  }
  while (latestnews.length < insights.length) {
    latestnews.push(<EmptyTextBox></EmptyTextBox>);
  }

  return (
    <div class="rowtoc">
      <div class="coltoc">
        <h3>Insights</h3>
        {insights}
      </div>
      <div class="coltoc">
        <h3>Latest News</h3>
        {latestnews}
      </div>
    </div>
    
  );
};

class NewsTable extends Component {
  render() {
    return (
      <Container>
        <Nav />
        <div class="rowtoc"></div>
        <CounterList></CounterList>
        <Footer/>
      </Container>
    );
  }
}

export default NewsTable;
