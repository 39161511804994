import React, { Component } from "react";
import Nav from "../../Nav";
import "./RASTableTop.css";
import "../../Table.css";
import Container from "../../Container";
import graph from "../../../img/graph.png";

class RASTableTop extends Component {
  render() {
    return (
      <Container>
        <Nav />

        <div class="textbox">
          <h4>
            Tabletop Exercises for Secure Robotic Assisted Surgery Systems
          </h4>
          <p>
            Robotic Assisted Surgery (RAS) Systems integrate various
            technologies to provide health delivery organizations with
            telesurgery capabilities. The RAS implements remote command and
            control capabilities through a surgical console that may be
            physically located in the operating environment or anywhere in the
            world. A rich set of cameras and sensors collect patient data and
            RAS telemetry and transmit to analysis services. Analysis may occur
            onsite or in the cloud. Communication may be enabled through Wi-Fi
            and through cellular protocols. Cloud services are useful in their
            ability to provide enhanced capabilities, such as maintaining
            digital twins of the RAS, running simulations and providing 3D
            visualizations. These technology integrations open up attack paths
            that should be analyzed for weaknesses within an HDOs enterprise
            security architecture.
          </p>
          <img src={graph}></img>
          <p>
            Source: Cloud Security Alliance (CSA) Telesurgery Tabletop Guide
            Book, 2023. TrustThink helped to lead the creation of the new&nbsp;
            <a href="https://www.linkedin.com/company/cloud-security-alliance/">
              Cloud Security Alliance
            </a>
            &nbsp;Telesurgery Tabletop Guide that will help health delivery
            organizations to plan and design impactful exercises using MITRE
            Attack Flows to identify and close cyber security gaps prior to
            implementing Robotic-Assisted Surgery (RAS) systems. You can
            download the document at:&nbsp;
            <a
              class="navl2"
              href="https://cloudsecurityalliance.org/artifacts/telesurgery-tabletop-guide-book/"
            >
              https://cloudsecurityalliance.org/artifacts/telesurgery-tabletop-guide-book/
            </a>
          </p>
        </div>
      </Container>
    );
  }
}

export default RASTableTop;
