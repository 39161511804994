import React, { useCallback, useEffect, useState } from 'react'

import aiImg from "../../img/aiethics.webp"
import autonomousImg from "../../img/autonomoustesting.webp"
import blockchainImg from "../../img/blockchain.webp"
import connectedvehiclesImg from "../../img/car3d.webp"
import encryptionImg from "../../img/encryptionimg-md.webp"
import incidentresponseImg from "../../img/incident.webp"
import medicaldevicesImg from "../../img/medicalDevices.webp"
import quantumImg from "../../img/quantum.webp"
import infrastructureImg from "../../img/infrastructure.webp"
import ButtonLearnMore from '../ButtonLearnMore'


import { NavLink } from 'react-router-dom'

const Carousel = () => {

  const [currentIndex, setCurrentIndex] = useState(0)
  const length = 9;

  const prevSlide = () => {
    const newIndex = currentIndex - 1;
    setCurrentIndex(newIndex < 0 ? length - 1 : newIndex);

  }
  const nextSlide = () => {
    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex >= length ? 0 : newIndex);  
  }
  
  return (

  

<div id="controls-carousel" class="relative w-full" data-carousel="static">

    <div class="relative h-56 overflow-hidden rounded-lg md:h-96">

        <div class= {currentIndex==0 ? "shown": "hidden"} data-carousel-item>
            <img src={aiImg} class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."/>
              <div class="absolute bottom-0 inset-x-0 backdrop-blur-sm bg-transparent text-white text-2xl text-center leading-4">AI Ethics and Governance 
              <br></br><br></br>
              <ButtonLearnMore buttonLink="/services/aiethics"></ButtonLearnMore></div>
              
        </div>

        <div class= {currentIndex==1 ? "shown": "hidden"} data-carousel-item>
            <img src={autonomousImg} class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."/>
            <div class="absolute bottom-0 inset-x-0 backdrop-blur-sm bg-transparent text-white text-2xl text-center leading-4">Autonomous Systems Security Testing
              <br></br><br></br>
              <ButtonLearnMore buttonLink="/services/autonomoustest"></ButtonLearnMore>
              </div>
              

        </div>

     
        <div class= {currentIndex==2 ? "shown": "hidden"} data-carousel-item>
            <img src={blockchainImg} class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."/>
            <div class="absolute bottom-0 inset-x-0 backdrop-blur-sm bg-transparent text-white text-2xl text-center leading-4">Blockchain Security Consulting 
              <br></br><br></br>
              <ButtonLearnMore buttonLink="/services/blockchain"></ButtonLearnMore>
              </div> 
              
        </div>

        <div class= {currentIndex==3 ? "shown": "hidden"} data-carousel-item>
            <img src={connectedvehiclesImg} class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."/>
            <div class="absolute bottom-0 inset-x-0 backdrop-blur-sm bg-transparent text-white text-2xl text-center leading-4">Connected Vehicles and ITS Security 
              <br></br><br></br>
              <ButtonLearnMore buttonLink="/services/connectedvehicles"></ButtonLearnMore>
              </div>
       
        </div>

        <div class= {currentIndex==4 ? "shown": "hidden"} data-carousel-item>
            <img src={encryptionImg} class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."/>
            <div class="absolute bottom-0 inset-x-0 backdrop-blur-sm bg-transparent text-white text-2xl text-center leading-4">Encryption and Key Management Architectures
              <br></br><br></br>
              <ButtonLearnMore buttonLink="/services/encryptionkey"></ButtonLearnMore>
              </div>
       
        </div>

        <div class= {currentIndex==5 ? "shown": "hidden"} data-carousel-item>
            <img src={incidentresponseImg} class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."/>
            <div class="absolute bottom-0 inset-x-0 backdrop-blur-sm bg-transparent text-white text-2xl text-center leading-4">Incident Response and Risk Management 
              <br></br><br></br>
              <ButtonLearnMore buttonLink="/services/incidentresponse"></ButtonLearnMore>
              </div>
       
        </div>

        <div class= {currentIndex==6 ? "shown": "hidden"} data-carousel-item>
            <img src={medicaldevicesImg} class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."/>
            <div class="absolute bottom-0 inset-x-0 backdrop-blur-sm bg-transparent text-white text-2xl text-center leading-4">Medical Device Cybersecurity
              <br></br><br></br>
              <ButtonLearnMore buttonLink="/services/medicaldevices"></ButtonLearnMore>
              </div>
       
        </div>

        <div class= {currentIndex==7 ? "shown": "hidden"} data-carousel-item>
            <img src={quantumImg} class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."/>
            <div class="absolute bottom-0 inset-x-0 backdrop-blur-sm bg-transparent text-white text-2xl text-center leading-4">Quantum Computing Impact Assessments
              <br></br><br></br>
              <ButtonLearnMore buttonLink="/services/quantum"></ButtonLearnMore>
              </div>
       
        </div>

        <div class= {currentIndex==8 ? "shown": "hidden"} data-carousel-item>
            <img src={infrastructureImg} class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."/>
            <div class="absolute bottom-0 inset-x-0 backdrop-blur-sm bg-transparent text-white text-2xl text-center leading-4">Smart Infrastructure Security Solutions

              <br></br><br></br>
              <ButtonLearnMore buttonLink="/services/infrastructure"></ButtonLearnMore>
              </div>
       
        </div>
        
    </div>

    <button type="button" onClick={prevSlide} class="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
            </svg>
            <span class="sr-only">Previous</span>
        </span>
    </button>
    <button type="button" onClick={nextSlide} class="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
            </svg>
            <span class="sr-only">Next</span>
        </span>
    </button>

</div>

      
     
    

  );
};



export default Carousel
