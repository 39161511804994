import React, { Component } from "react";
import Nav from "../Nav";
import "./ServiceCryptography.css";
import Container from "../Container";

import TableListCrypto from "../TableListCrypto";
class ServiceCryptography extends Component {
  render() {
    return (
      <Container>
        <div id="cybersol_div">
          <Nav />
        </div>
        <div className="main_marg centerr">
          <h1 className="toppadTWO mobileh1">
            Cryptography and Key Management
          </h1>
          <h4 className="mobileh4">
            Contact Us for On-Demand Cyber Security Engineering Services{" "}
          </h4>
          <a href="tel:619-804-8840">619-804-8840</a>
        </div>
        <TableListCrypto />

        <div className="main_marg leadSmall">
          <br></br>
          <h6>Evolve to Quantum Resistant Cryptography</h6>
          <p>
            Practical quantum computing is on the horizon. Elliptic Curve and
            other asymmetric crypto schemes are being phased out for new
            approaches. Symmetric algorithms will require increased key sizes.
            Existing cryptographic protocols may or may not support these new
            algorithms and key sizes. Understand how the looming advent of
            quantum computing impacts your current cryptographic posture and
            design a new cryptographic architecture that takes all of this into
            account. Contact us to learn more about this threat or to begin
            updating your organization’s cryptography.
          </p>

          <br></br>
          <h6>Integrate Strong Cryptography for IoT Devices</h6>
          <p>
            Internet of Things (IoT) vendors are often blamed for not providing
            strong cyber security features. The size, weight and power
            constraints of the IoT makes this difficult. As customer
            organizations start to think about evolving to a Zero Trust
            Architecture (ZTA) however, you’ll need to integrate a robust Root
            of Trust (RoT) into your designs. Work with TrustThink to understand
            your options for integrating strong crypto into your IoT products.
          </p>

          <br></br>
          <h6>Create a Key and Certificate Management Architecture (KCMA) </h6>
          <p>
            Key Management can be a complex topic. Keys are used for a variety
            of purposes. Symmetric keys can be created by two endpoints through
            key agreement such as elliptic curve Diffie Hellman (ECDH) or can be
            shared using key transport such as wrapping in a Key Encryption Key
            (KEK). Key usages, lifetimes and update procedures must also be
            defined. Pseudo-Random Number Generators (PRNGs) must be used to
            generate high quality keys. Certificates must be trusted and bound
            to asymmetric key pairs. Trust Stores and trust store update
            processes must be securely managed across all devices. Revocation
            procedures must also be designed. Work with TrustThink to design
            your organization or systems’ Key and Certificate Management
            Architecture.
          </p>

          <br></br>
          <h6>Implement a Public Key Infrastructure (PKI) </h6>
          <p>
            PKI serves as the foundation for a multitude of cyber security
            services. There are many options for generating and managing
            certificates within your organization. Work with TrustThink to
            understand whether you should implement your own PKI, procure PKI
            services or deploy a hybrid approach.
          </p>
        </div>

      </Container>
    );
  }
}

export default ServiceCryptography;
