import React, { Component } from "react";
import "../Table.css";
import "./ServiceEncryption.css";
import Nav from "../Nav";
import Container from "../Container";
import Footer from "../Footer"
class ServiceEncryption extends Component {
  render() {
    return (
      <Container>
        <Nav />
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">Services </h2>
              <p class="opacity">
                <i></i>
              </p>
              <h1 class="lead highfont">
                Work with TrustThink to securely design, develop and deploy new
                technologies.
              </h1>
            </div>
          </div>
        </div>

        <div class="grid9">
          <img class="enimg" alt="Encryption earth"></img>
          <div class="content">
            <div>
              <h2>
                Contact Us for On-Demand Cyber Security Engineering Services at{" "}
                <a href="mailto:solutions@trustthink.net">
                  {" "}
                  solutions@trustthink.net
                </a>
              </h2>

              <p class="mb-5">
                <br></br>
                TrustThink works with some of the largest organizations in the
                world to modernize cryptography and implement scalable
                cryptographic key management practices. Our engineers have deep
                expertise in Public Key Infrastructure (PKI), Virtual Private
                Networks (VPNs) and cryptographic protocols. Work with
                TrustThink to develop key management plans and architectures,
                move to quantum-resistant crypto, and to integrate strong
                cryptography into your Internet of Things (IoT) device
                developments.
              </p>
              <h2>Evolve to Quantum Resistant Cryptography </h2>

              <p class="">
                Practical quantum computing is on the horizon. Elliptic Curve
                and other asymmetric crypto schemes are being phased out for new
                approaches. Symmetric algorithms will require increased key
                sizes. Existing cryptographic protocols may or may not support
                these new algorithms and key sizes. Understand how the looming
                advent of quantum computing impacts your current cryptographic
                posture and design a new cryptographic architecture that takes
                all of this into account. Contact us to learn more about this
                threat or to begin updating your organization’s cryptography.
              </p>

              <div class="">
                <h2>Integrate Strong Cryptography for IoT Devices </h2>

                <p class="">
                  Internet of Things (IoT) vendors are often blamed for not
                  providing strong cyber security features. The size, weight and
                  power constraints of the IoT makes this difficult. As customer
                  organizations start to think about evolving to a Zero Trust
                  Architecture (ZTA) however, you’ll need to integrate a robust
                  Root of Trust (RoT) into your designs. Work with TrustThink to
                  understand your options for integrating strong crypto into
                  your IoT products.
                </p>
                {/*<br></br>
                  <NavLink to="/services/cryptography">Learn More</NavLink> */}
              </div>

              <div class="">
                <h2>
                  Create a Key and Certificate Management Architecture (KCMA){" "}
                </h2>

                <p class="">
                  Key Management can be a complex topic. Keys are used for a
                  variety of purposes. Symmetric keys can be created by two
                  endpoints through key agreement such as elliptic curve Diffie
                  Hellman (ECDH) or can be shared using key transport such as
                  wrapping in a Key Encryption Key (KEK). Key usages, lifetimes
                  and update procedures must also be defined. Pseudo-Random
                  Number Generators (PRNGs) must be used to generate high
                  quality keys. Certificates must be trusted and bound to
                  asymmetric key pairs. Trust Stores and trust store update
                  processes must be securely managed across all devices.
                  Revocation procedures must also be designed. Work with
                  TrustThink to design your organization or systems’ Key and
                  Certificate Management Architecture.
                </p>
              </div>

              <div class="">
                <h2>Implement a Public Key Infrastructure (PKI)</h2>

                <p class="">
                  PKI serves as the foundation for a multitude of cyber security
                  services. There are many options for generating and managing
                  certificates within your organization. Work with TrustThink to
                  understand whether you should implement your own PKI, procure
                  PKI services or deploy a hybrid approach.
                </p>
              </div>
            </div>
          </div>
        </div>
        

        <div class="grid10"></div>
        <Footer/>
        <script src="assets/js/jquery.min.js"></script>
        <script src="assets/bootstrap/js/bootstrap.min.js"></script>
      </Container>
    );
  }
}

export default ServiceEncryption;
