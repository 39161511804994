import React, { Component } from "react";

import "../Table.css";
import Container from "../Container";
class TableListHealth extends Component {
  render() {
    return (
      <Container>
        <body>
          <section class="showcase">
            <div class="container-fluid p-0 ">
              <div class="row no-gutters">
                <div class="col-lg-5 order-lg-1 text-white showcase-img picture7">
                  <span></span>
                </div>
                <div class="col-lg-6 my-auto order-lg-1 showcase-text">
                  <p class="lead mb-0">
                    TrustThink led medical device security architecture
                    development for a Nationwide Health Delivery Organization
                    (HDO) with hundreds of thousands of deployed medical
                    devices. Our team developed innovative risk management
                    processes and security architecture patterns based on threat
                    modeling tailored to medical devices. Our work included the
                    entire range of medical devices, software-as-a-medical
                    device, Extended Reality (XR) devices, and Special Purpose
                    Devices (SDS) used within clinical and research settings.
                  </p>
                </div>
              </div>
              <br></br>
              <div class="row no-gutters">
                <div class="col-lg-12 my-auto order-lg-3 showcase-text ">
                  <p class="lead mb-0 paddingLR">
                    TrustThink is also leading a team developing a new industry
                    guide for medical device incident response (IR) through the
                    Cloud Security Alliance (CSA) Internet of Things (IoT)
                    Working Group. This guide integrates clinical care factors
                    with traditional incident response decision making to ensure
                    that patient-safety is woven throughout incident response.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
        </body>
      </Container>
    );
  }
}

export default TableListHealth;
