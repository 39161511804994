import React, { Component } from "react";

import "../Table.css";
import Container from "../Container";
import { NavLink } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";

class Footer extends Component {



  render() {
    return (
      <Container>
        <footer class="bg-gray-800">
            <div class="mx-auto w-full max-w-screen-xl">
                <div class="flex items-stretch justify-between lg:py-4 row-span-4">
                <div>
                        <h2 class="mb-3 text-lg font-semibold text-white">Services</h2>
                        <ul class="grid grid-cols-3 text-gray-500 font-medium" >
                            <li class="mb-3">
                                <NavLink to="/services/aiethics" >AI Ethics and Governance <ScrollToTop/> </NavLink>
                                <ScrollToTop/>
                            </li>
                            <li class="mb-3">
                                <NavLink to="/services/autonomoustest" >Autonomous Systems Security Testing</NavLink>
                            </li>
                            <li class="mb-3">
                                <NavLink to="/services/blockchain" >Blockchain Security Consulting</NavLink>
                            </li>

                            <li class="mb-3">
                                <NavLink to="/services/connectedvehicles" >Connected Vehicles and ITS Security</NavLink>
                            </li>
                            <li class="mb-3">
                                <NavLink to="/services/encryptionkey" >Encryption and Key Management</NavLink>
                            </li>
                           
                            <li class="mb-3">
                                <NavLink to="/services/medicaldevices" >Medical Device Cybersecurity</NavLink>
                            </li>
                            
                            <li class="mb-3">
                                <NavLink to="/services/quantum" >Quantum Computing Assessments</NavLink>
                            </li>

                            <li class="mb-3">
                                <NavLink to="/services/incidentresponse" >Risk Management</NavLink>
                            </li>

                            <li class="mb-3">
                                <NavLink to="/services/infrastructure" >Smart Infrastructure Security Solutions</NavLink>
                            </li>      
                            
                        </ul>
                        
                    </div>
            
                    <div>
                        <h2 class="mb-3 text-lg font-semibold text-white">Solutions</h2>
                        <ul class="text-gray-500 dark:text-gray-400 font-medium">
                            <li class="mb-3">
                                <NavLink to="/cybersolutions/cmm"> AI CMM Workshops </NavLink>
                            </li>
                            <li class="mb-3">
                                <NavLink to="/cybersolutions/medical">Medical Device Cybersecurity</NavLink>
                            </li>
                            <li class="mb-3">
                                <NavLink to="/cybersolutions/dronedefense" class="hover:underline">Aerial Drone Cybersecurity</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 class="mb-3 text-lg font-semibold text-white">Company</h2>
                        <ul class="text-gray-500 dark:text-gray-400 font-medium">
                            <li class="mb-3">
                                <NavLink to="/about"> About Us </NavLink>
                            </li>

                            <li class="mb-3">
                                <NavLink to="/news"> News and Insights </NavLink>
                            </li>

                            <li class = "mb-3">
                                <a href="mailto:solutions@trustthink.net">
                                    <div>solutions@trustthink.net</div>
                                </a>
                            </li>

                            <li>
                                1-888-385-1782
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
         </footer>
         

      </Container>
    );
  }
}

export default Footer;
