import React, { Component } from "react";

import "../../Table.css";
import Container from "../../Container"
import Footer from "../../Footer"
import { NavLink } from "react-router-dom";
import aiImg from "../../../img/aiethics.webp"
import Nav from "../../Nav";

class ServiceAIEthics extends Component {
  render() {
    return (
      <Container>
    <Nav />
        <div id="cybersol_div">
          <div class="stretch">
            <div class="container content center padding-64 tester" id="head">
              <h2 class="wide">Services </h2>
              <p class="opacity">
                <i></i>
              </p>
              <h1 class="lead highfont">
                Work with TrustThink to securely design, develop and deploy new
                technologies.
              </h1>
            </div>
          </div>
        </div>
        <body>
          <div class="grid10 addmargin">

          <div class="title">
          <h2>AI Ethics and Governance</h2>
            </div>

            <img class="service_images" src={aiImg} alt="Neural networks" style= {{height: 400, width: 800}}/>

            <div class="imgtext">
          
              <p>
              <strong>Understanding the Need:</strong> In the rapidly advancing field of artificial intelligence, ethical considerations and governance are paramount, 
              especially in sensitive sectors like healthcare and defense. AI systems can have profound implications on privacy, autonomy, and decision-making processes, 
              necessitating stringent ethical guidelines.
              </p>

              <p>
              <strong>TrustThink's Experience:</strong> Leveraging our leadership in developing the Transportation AI Capability Maturity Model (CMM), which incorporates ethical considerations, 
              TrustThink is at the forefront of framing ethical guidelines for AI deployment. This experience provides us with a unique perspective on the integration of ethical practices into AI
               development and usage.
              </p>

              <p>
              <strong>Developing Ethical Guidelines and Governance Frameworks:</strong> TrustThink consults on the creation of robust ethical guidelines and governance frameworks that ensure AI 
              technologies are used responsibly. Our services focus on aligning AI deployments with core ethical principles and regulatory requirements, ensuring that these technologies are both 
              innovative and trustworthy.
              </p>

              <p>
              <strong>Tailoring Ethics to Sector Needs:</strong> Our approach involves customizing ethical frameworks to fit the specific needs and challenges of sectors like healthcare and defense, 
              where the impact of AI can be particularly significant. We help organizations navigate the complexities of AI ethics to foster trust and accountability in their AI initiatives.
              </p>


            </div>
            <div class="bottom">
                Discover how TrustThink can empower your organization with leading-edge solutions tailored to the unique challenges of your industry. Contact us today to safeguard your technologies and propel your innovations forward.
               <p>
                <a href="mailto:solutions@trustthink.net">
                            <strong>SOLUTIONS@TRUSTTHINK.NET</strong>
                </a>
              </p>
              
              
            </div>
            {/* Back button */}
            <NavLink to="/services">
              <button type="button" class="back_btn">                            
                <svg class="w-5 h-5 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                               
              </button>
            </NavLink>

          </div>

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          />
        </body>
        <Footer/>
      </Container>
    );
  }
}

export default ServiceAIEthics;
