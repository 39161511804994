import React, { Component } from "react";
import Nav from "../Nav";
import "./ServiceCommercialMV.css";
import Container from "../Container";
import think from "../images/think.png";
import TableListVehicles from "../TableListVehicles";
class ServiceCommercialMV extends Component {
  render() {
    return (
      <Container>
        <div id="cybersol_div">
          <Nav />
        </div>
        <div className="main_marg centerr">
          <h2 className="toppadTWO mobileh1">
            Commercial Motor Vehicle and ADAS Cyber Security for Fleet Owners
          </h2>
          <h4 className="mobileh4">
            Contact Us for On-Demand Cyber Security Engineering Services{" "}
          </h4>
          <a href="tel:619-804-8840">619-804-8840</a>
        </div>

        <p></p>

        <TableListVehicles />
        <br></br>
        <br></br>
        <div className="main_marg">
          <h4>TrustThink Offers: </h4>
          <br></br>
          <h6>Vehicle Acquisition Security Evaluation </h6>
          <p>
            Integrate a cyber security evaluation of vehicles and features into
            operator risk management processes. Understand vehicle security
            posture and identify gaps and required mitigating controls to ensure
            vehicles and their interfaces don’t open unintended holes in your
            networks.
          </p>

          <br></br>
          <h6>Secure Vehicle ADAS </h6>
          <p>
            Today’s vehicles integrate new controllers and ECUs for ADAS
            features. Existing concerns such as J1939/ CAN Bus segmentation and
            security, wireless telematics, and infotainment weaknesses are
            magnified with the addition of sensors, sensor processing, mapping
            and specialized ADAS ECUs and controllers. Identify and mitigate
            weaknesses in ADAS integrations to assure a safe and secure driving
            experience.
          </p>

          <br></br>
          <h6>Security Testing </h6>
          <p>
            Work with TrustThink to perform security testing of your vehicles.
            Leverage our existing test suite of hardware, software /firmware and
            RF tools to identify vulnerabilities. Design your own re-usable test
            plan that can be used to test new devices.
          </p>

          <br></br>
          <h6>Incident Response Planning </h6>
          <p>
            The incident response process for automobiles is unique. There may
            be requirements for real-time driver/vehicle interaction based on
            the timing and type of security event. Work with us to document and
            exercise an incident response plan tailored to your unique fleet
            operation.
          </p>
        </div>
        <p></p>
      </Container>
    );
  }
}

export default ServiceCommercialMV;
